import React from 'react';
import documentNumberMask from '~/lib/masks/documentNumber';
import phoneNumberMask from '~/lib/masks/phoneNumber';
import { Person } from '~/types';

type PersonShowProps = {
  person: Person,
}

const PersonShow: React.FC<PersonShowProps> = ({ person }) => (
  <div>
    <div className="columns">
      <div className="column pb-2">
        <b>Nome do Doador:&nbsp;</b>
        {person?.fullName}
      </div>
      <div className="column pb-2">
        <b>Telefone:&nbsp;</b>
        {
          person?.phoneNumber
            ? phoneNumberMask(person?.phoneNumber)
            : '-'
        }
      </div>
    </div>
    <div className="columns">
      <div className="column pb-2">
        <b>Número do Documento:&nbsp;</b>
        {documentNumberMask(person?.documentNumber)}
      </div>
      <div className="column pb-2">
        <b>E-mail:&nbsp;</b>
        {person?.email}
      </div>
    </div>
    <div className="columns">
      <div className="column pb-2">
        <b>Endereço:&nbsp;</b>
        {person?.address?.formattedAddress}
      </div>
    </div>
  </div>
);

export default PersonShow;
