import React from 'react';
import { Card } from '~/components';
import thumbor from '~/lib/thumbor';

type TutorProps = {
  tutor: {
    fullName: string,
    photoURL: string,
    description: string,
  },
}

const Tutor: React.FC<TutorProps> = ({ tutor }) => (
  <div className="column col-6 col-sm-12">
    <Card.Base className="card custom">
      <Card.Header>
        <div className="align-center">
          <div className="h5">Tutor/Responsável</div>
        </div>
      </Card.Header>
      <Card.Body>
        <div className="columns align-center">
          <div className="column col-auto">
            <figure className="avatar avatar-xl" data-initial={tutor?.fullName?.[0]}>
              {
                Boolean(tutor?.photoURL)
                && (
                  <img
                    src={thumbor(tutor?.photoURL, { width: 64, height: 64 })}
                    alt="Avatar do pet"
                  />
                )
              }
            </figure>
          </div>
          <div className="column">
            <div className="text-bold">{tutor?.fullName}</div>
            <div className="text-small">{tutor?.description}</div>
          </div>
        </div>
      </Card.Body>
    </Card.Base>
  </div>
);

export default Tutor;
