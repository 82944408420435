import React from 'react';
import { PageContainer, Styles } from '~/components';
import { NavLink, Outlet } from 'react-router-dom';
import useQuery from '~/hooks/useQuery';

const Navbar: React.FC = () => {
  const query = useQuery();

  if (query?.hideNavbar) return <Outlet />;

  return (
    <>
      <div className="py-2 only-print">
        <PageContainer>
          <header className="navbar">
            <section className="navbar-section">
              <NavLink to="/" className="navbar-brand mr-2">
                <div className="d-flex align-center">
                  <Styles.Logo style={{ width: 20 }} />
                  &nbsp;
                  <b>Petis</b>
                </div>
              </NavLink>
            </section>
          </header>
          <div className="divider" />
        </PageContainer>
      </div>
      <Outlet />
    </>
  );
};

export default Navbar;
