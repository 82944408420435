import React from 'react';
import QRCode from 'react-qr-code';
import { PageContainer } from '~/components';
import usePageTitle from '~/hooks/usePageTitle';

// import { Container } from './styles';

const Show: React.FC<{title: string}> = ({ title }) => {
  usePageTitle({ title });

  return (
    <PageContainer>
      <div className="flex-centered" style={{ height: '100vh' }}>
        <div>
          <div className="flex-centered mb-2">
            <QRCode value="https://onelink.to/s72f5s" size={200} />
          </div>
          <div className="h3 text-center my-2 pt-2 text-primary">Baixe o Petis no seu celular</div>
          <div className="text-center mt-2">
            Para acessar os chats você precisa utilizar o aplicativo Petis
          </div>
          <div className="text-center mt-2">
            Aponte a câmera do seu celular para o QR Code ou
            {' '}
            <a href="https://onelink.to/s72f5s"><b>clique aqui para baixar</b></a>
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

export default Show;
