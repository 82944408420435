import React, { useMemo } from 'react';
import clinicalCareStatus from '~/constants/enumTranslations/clinicalCareStatus';
import { ClinicalCareEvent } from '~/types';
import dateMask from '~/lib/masks/date';
import thumbor from '~/lib/thumbor';
import mapEvents from './mapEvents';

const authorDetailsMap = {
  tutor: {
    icon: 'fas fa-user',
    description: 'Tutor / Responsável',
  },
  clinic: {
    icon: 'fas fa-house-chimney-medical',
    description: 'Clínica',
  },
  provider: {
    icon: 'fas fa-user-secret',
    description: 'Fornecedor',
  },
  author: {
    icon: 'fas fa-user-edit',
    description: 'Autor',
  },
};

type TimelineItemProps = {
  event: ClinicalCareEvent
  clinicalCare: { tutorId?: number, clinicId: number, authorId: number, providerId: number },
  last: boolean
};

const TimelineItem: React.FC<TimelineItemProps> = ({ event, clinicalCare, last }) => {
  const mappedEvent = mapEvents(event?.status);
  const {
    tutorId, clinicId, authorId, providerId,
  } = clinicalCare;

  const authorDetails = useMemo(() => {
    if (event.authorId === tutorId) return authorDetailsMap?.tutor;
    if (event.authorId === clinicId) return authorDetailsMap.clinic;
    if (event.authorId !== providerId && event.authorId === authorId) {
      return authorDetailsMap.author;
    }

    return authorDetailsMap.provider;
  }, [event, clinicalCare]);

  return (
    <div className="inside-avoid">
      <div>
        <span
          className={`timeline-icon tooltip tooltip-right ${mappedEvent?.bgIconClass} ${mappedEvent?.icon ? 'icon-lg' : ''}`}
          data-tooltip={clinicalCareStatus(event.status)}
        />
      </div>
      <div>
        <div className="tile">
          <div className="tile-content">
            <p className="tile-subtitle">
              <span className="text-bold text-small">
                {dateMask(event?.createdAt, { dateStyle: 'full' })}
                &nbsp;
              </span>
              <small className="label label-sm label-rounded">
                <i className="far fa-clock" />
                &nbsp;
                {new Date(event?.createdAt).toLocaleTimeString()}
              </small>
              <small className="label label-sm label-rounded ml-1">
                <i className={authorDetails.icon} />
                &nbsp;
                {authorDetails.description}
              </small>
            </p>
            <p className="tile-title pre">{mappedEvent?.title}</p>
            {
              Boolean(event?.description) && (
                <p className="tile-title">
                  <cite className="pre">{event?.description}</cite>
                </p>
              )
            }
            {
              Boolean(event?.attachments) && (
                event.attachments.map((url) => (
                  <img
                    key={url}
                    src={url}
                    width={230}
                    height={230}
                    className="s-rounded pr-2"
                    alt="Imagem do evento"
                  />
                ))
              )
            }
            {
              Boolean(event?.address) && (
                <div className="my-2">
                  <small>
                    Endereço:&nbsp;
                    <b>{event?.address?.formattedAddress}</b>
                  </small>
                </div>
              )
            }
            <div className="text-ellipsis">
              <figure className="avatar avatar-xs" data-initial={event?.author?.fullName?.[0]}>
                {
                  Boolean(event?.author?.photoURL)
                  && (
                    <img
                      src={
                        thumbor(
                          event?.author?.photoURL,
                          { width: 48, height: 48 },
                        )
                      }
                      alt="Avatar do tutor"
                    />
                  )
                }
              </figure>
              <span className="ml-2 text-small">{event?.author?.fullName}</span>
            </div>
            {
              event.outsourcedTransport
              && (
                <div className="mt-2 pt-2">
                  <small>
                    <div className="chip">
                      <i className="fas fa-ambulance" />
                      &nbsp;Transporte terceirizado
                    </div>
                  </small>
                </div>
              )
            }
          </div>
        </div>
      </div>
      {!last && <div className="divider" />}
    </div>
  );
};

export default TimelineItem;
