import React from 'react';
import dateMask from '~/lib/masks/date';
import thumbor from '~/lib/thumbor';

type Comment = {
  'id': number
  'createdAt': string
  'updatedAt': string
  'description': string
  'commentableType': string
  'commentableId': number
  'attachmentURL': string
  'author': {
    'id': number
    'createdAt': string
    'updatedAt': string
    'documentNumber': string
    'photoURL': string
    'fullName': string
    'displayName': string
    'deleted': boolean
    'email': string
    'emailVerified': boolean
    'phoneNumber': string
    'isOwner': boolean
    'location': {
      'type': string
      'coordinates': [
        number,
        number
      ]
    },
    'cityId': number
    'addressId': number
  },
  'personId': number
  'authorId': number
}

type CommentsProps = {
  comments: Comment[]
}

const Comments = ({ comments }: CommentsProps) => (
  <>
    {
      comments.map((comment) => (
        <div key={comment.id} className="mb-2 pb-2">
          <div className="columns mb-2">
            <div className="column col-auto">
              <figure className="avatar avatar-lg" data-initial={comment.author.fullName?.[0]}>
                {
                  Boolean(comment.author.photoURL)
                  && (
                    <img
                      src={thumbor(comment.author.photoURL, { width: 64, height: 64 })}
                      alt="Avatar da pessoa"
                    />
                  )
                }
              </figure>
            </div>
            <div className="column">
              <div className="text-bold">{comment.author.fullName}</div>
              <div>
                <small>
                  Em
                  {' '}
                  {dateMask(comment.createdAt, { dateStyle: 'full' })}
                </small>
                &nbsp;
                <small className="label label-sm label-rounded">
                  <i className="far fa-clock" />
                  &nbsp;
                  {new Date(comment.createdAt).toLocaleTimeString()}
                </small>
              </div>
            </div>
          </div>
          <div className="pre">
            {comment.description}
          </div>
          {
            Boolean(comment.attachmentURL) && (
              <img
                src={thumbor(comment.attachmentURL, { width: 230, height: 230 })}
                className="s-rounded"
                alt="Anexo do comentário"
              />
            )
          }
        </div>
      ))
    }
  </>
);

export default Comments;
