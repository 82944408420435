import { AdoptionStatus } from '~/types';

const translations = {
  requested: 'Solicitada',
  confirmed: 'Confirmada',
  rejected: 'Rejeitada',
  canceled: 'Cancelada',
};

export default function castrationStatus(status: AdoptionStatus): string {
  return translations[status] || status;
}
