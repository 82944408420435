import { AxiosError } from 'axios';
import envs from '~/constants/envs';

export type ApiError = {
  code: string
  message: string
  additionalProperties?: any
}

export function errorHandlerInterceptor() {
  return (error: AxiosError) => {
    if (error.message === 'Network Error') {
      const e = {
        code: 'networkError',
        message: `Não foi possível conectar ao servidor ${envs.REACT_APP_BASE_API_URL}`,
      };
      return Promise.reject(e);
    }
    const e: ApiError = {
      code: error.response?.data?.code,
      message: error?.response?.data?.message,
      additionalProperties: error?.response?.data?.additionalProperties,
    };

    return Promise.reject(e);
  };
}
