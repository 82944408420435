import React from 'react';
import documentNumberMask from '~/lib/masks/documentNumber';
import phoneNumberMask from '~/lib/masks/phoneNumber';
import { Person } from '~/types';

type NewTutorProps = {
  newTutor: Person,
}

const NewTutor: React.FC<NewTutorProps> = ({ newTutor }) => (
  <div>
    <div className="columns">
      <div className="column pb-2">
        <b>Nome do adotante:&nbsp;</b>
        {newTutor?.fullName}
      </div>
      <div className="column pb-2">
        <b>Telefone:&nbsp;</b>
        {
          newTutor?.phoneNumber
            ? phoneNumberMask(newTutor?.phoneNumber)
            : '-'
        }
      </div>
    </div>
    <div className="columns">
      <div className="column pb-2">
        <b>Número do Documento:&nbsp;</b>
        {documentNumberMask(newTutor?.documentNumber)}
      </div>
      <div className="column pb-2">
        <b>E-mail:&nbsp;</b>
        {newTutor?.email}
      </div>
    </div>
    <div className="columns">
      <div className="column pb-2">
        <b>Endereço:&nbsp;</b>
        {newTutor?.address?.formattedAddress}
      </div>
    </div>
  </div>
);

export default NewTutor;
