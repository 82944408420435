import { MistreatmentStatus } from '~/types';

const translations = {
  opened: 'Aberto',
  checking: 'Em verificação',
  done: 'Pronto',
  rejected: 'Rejeitado',
  canceled: 'Cancelado',
};

export default function mistreatmentStatus(status: MistreatmentStatus): string {
  return translations[status] || status;
}
