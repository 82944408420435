import { CastrationStatus } from '~/types';

const mapping = {
  requested: {
    title: 'Castração solicitada.\n',
    icon: '',
  },
  rejected: {
    title: 'Castração rejeitada.\n',
    bgIconClass: 'bg-error',
    icon: 'fas fa-ban',
  },
  canceled: {
    title: 'Castração cancelada\n',
    icon: 'fas fa-question',
  },
  revision: {
    title: 'Castração enviada para revisão\n',
    icon: 'fas fa-question',
  },
  correction: {
    title: 'Castração aguardando a correção do tutor\n',
    icon: 'fas fa-edit fa-xs',
  },
  corrected: {
    title: 'O tutor informou que efetuou a correção\n',
    icon: 'fas fa-check',
  },
  approved: {
    title: 'Castração aprovada.\n',
    bgIconClass: 'bg-success',
    icon: 'fas fa-check',
  },
  attendance: {
    title: 'Atendimento iniciado.\n',
    icon: 'fas fa-xs fa-play',
  },
  awaitingConfirmation: {
    title: 'A clínica concluiu a castração.\nO tutor deve confirmar o serviço e avaliar a clínica\n',
    bgIconClass: 'bg-success',
    icon: 'fas fa-clock',
  },
  completed: {
    title: 'Atendimento confirmado\n',
    bgIconClass: 'bg-success',
    icon: 'fas fa-star',
  },
  scheduled: {
    title: 'Castração agendada\n',
    icon: 'fas fa-calendar',
  },
  preApproved: {
    title: 'Castração pré-aprovada\n',
    icon: 'fas fa-check-circle',
  },
  inTreatment: {
    title: 'A clínica iniciou um tratamento para o pet\n',
    icon: 'fas fa-briefcase-medical',
  },
};

type ReturnType = {title?: string, bgIconClass?: string, icon?: string}
export default function mapEvents(status: CastrationStatus): ReturnType {
  return mapping?.[status] || {};
}
