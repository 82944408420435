import styled from 'styled-components';

export const CopyButton = styled.button.attrs({ type: 'button', className: 'bg-primary tooltip tooltip-top' })`
  width: 22px;
  height: 22px;
  border-radius: 11px;
  color: #fff;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 12px;
  cursor: pointer;
`;
