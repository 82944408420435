import envs from '~/constants/envs';
import axios from 'axios';
import qs from 'qs';
import { errorHandlerInterceptor } from './interceptors';

const api = axios.create({
  baseURL: `${envs.REACT_APP_BASE_API_URL}/web-show`,
  paramsSerializer: qs.stringify,
});
api.interceptors.response.use((response) => response, errorHandlerInterceptor());

export default api;
