import React, { useState } from 'react';
import { Card, PageContainer, Styles } from '~/components';
import MaskedInput from 'react-text-mask';
import { RGA_PATTERN } from '~/lib/textMaskPatterns';
import { useNavigate } from 'react-router-dom';
import usePageTitle from '~/hooks/usePageTitle';

const AdoptionTerm: React.FC<{ title: string }> = ({ title }) => {
  const [rga, setRga] = useState('');
  const navigate = useNavigate();
  usePageTitle({ title });

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    navigate(`/adoption-term/${rga}`);
  }

  return (
    <PageContainer>
      <form onSubmit={onSubmit}>
        <div className="full-header-height flex-centered">
          <Card.Base style={{ width: '40%', maxWidth: 500 }}>
            <Card.Header>
              <div className="columns align-center col-gapless">
                <div className="column col-auto mr-2 mt-1">
                  <Styles.Logo style={{ width: 28 }} />
                </div>
                <div className="column">
                  <div className="h5">Imprimir Termo de Adoção</div>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="divider" />
              <div className="text-center py-2">
                O
                {' '}
                <b>Número do RGA</b>
                {' '}
                está disponível no Petis. O atual tutor deve abrir o aplicativo e procurar o pet em
                &ldquo;Meus Pets&rdquo;.
              </div>
              <div className="divider" />
              <div className="form-group text-center py-2">
                <label htmlFor="input-rga">Entre com o Número do RGA do Pet doado/adotado</label>
                <MaskedInput
                  className="form-input text-center"
                  placeholder='Ex.: "9999.9999.9999.9999.9999"'
                  mask={RGA_PATTERN}
                  onChange={({ target: { value } }) => setRga(value.replace(/\D/g, ''))}
                  type="tel"
                  id="input-rga"
                />
              </div>
              <div className="divider" />
            </Card.Body>
            <Card.Footer>
              <div className="text-right">
                <button type="submit" disabled={rga.length < 20} className="btn btn-primary">
                  <i className="fas fa-search" />
                  &nbsp;Buscar Pet
                </button>
              </div>
            </Card.Footer>
          </Card.Base>
        </div>
      </form>
    </PageContainer>
  );
};

export default AdoptionTerm;
