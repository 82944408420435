import React from 'react';
import { useParams } from 'react-router-dom';
import {
  LoadManager, PageContainer, Share, Styles,
} from '~/components';
import useAxiosRequest from '~/hooks/useAxiosRequest.1';
import usePageTitle from '~/hooks/usePageTitle';
import dateMask from '~/lib/masks/date';
import adoptionFairs from '~/services/adoptionFairs';
import Animals from '~/views/AdoptionFair/Show/Animals';
import { mapping } from '~/views/AdoptionFair/Show/mapStatus';
import { Header, Sticky } from '~/views/AdoptionFair/Show/styles';

const Show: React.FC<{ title: string }> = ({ title }) => {
  usePageTitle({ title });
  const { id } = useParams();

  const fairOptions = useAxiosRequest(adoptionFairs.show, id);

  const adoptionFair = fairOptions.data;

  // eslint-disable-next-line prefer-destructuring
  const status: keyof typeof mapping = adoptionFair?.status;
  const shareTitle = `Acesse agora mesmo a feirinha no Petis\n${adoptionFair?.title}\nOrganizado por: ${adoptionFair?.author?.fullName || 'Petis'}\n\n`;

  return (
    <PageContainer>
      <LoadManager apiStatus={fairOptions.status} error={fairOptions.error}>
        {
          Boolean(adoptionFair) && (
            <>
              <Header>
                <div className="column col-auto">
                  <Styles.Logo style={{ width: 100 }} />
                </div>
                <div className="column">
                  <h1 className="h4">{adoptionFair.title}</h1>
                  {
                    Boolean(adoptionFair.author) && (
                    <div>
                      <span>
                        Organizado por:
                        {' '}
                        <b>{adoptionFair.author.fullName}</b>
                      </span>
                    </div>
                    )
                  }
                  <div>
                    <small>
                      De
                      {' '}
                      <b>{dateMask(adoptionFair.beginIn)}</b>
                      {' '}
                      até
                      {' '}
                      <b>{dateMask(adoptionFair.endIn)}</b>
                    </small>
                  </div>
                  {
                    Boolean(adoptionFair.location) && (
                      <div className="mt-2">
                        <small>
                          <a
                            href={`https://maps.google.com/?q=${adoptionFair?.location?.coordinates?.[1]},${adoptionFair?.location?.coordinates?.[0]}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {adoptionFair?.address?.formattedAddress}
                          </a>
                        </small>
                      </div>
                    )
                  }
                </div>
              </Header>
              <Sticky>
                <div className={`${mapping?.[status]?.class} my-2`}>
                  <i className={mapping?.[status]?.icon} />
                      &nbsp;
                  <cite>{mapping?.[status]?.message }</cite>
                </div>
                <div className="my-2">
                  <div className="columns">
                    <div className="column col-auto align-center">
                      <small>Compartilhar feirinha:</small>
                    </div>
                    <div className="column">
                      <Share url={window.location.href} title={shareTitle} />
                    </div>
                  </div>
                </div>
                <div className="divider" />
              </Sticky>
              <h2 className="h5">Pets na feira</h2>
              <Animals id={id as string} />
            </>
          )
        }
      </LoadManager>
    </PageContainer>
  );
};

export default Show;
