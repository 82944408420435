import React from 'react';
import { Card, PageContainer, Styles } from '~/components';
import { NavLink } from 'react-router-dom';
import usePageTitle from '~/hooks/usePageTitle';
import pack from '../../../package.json';

const Home: React.FC<{ title: string }> = ({ title }) => {
  usePageTitle({ title });

  return (
    <PageContainer>
      <div className="full-vh flex-centered">
        <Card.Base style={{ width: '40%', minWidth: 300, maxWidth: 500 }}>
          <Card.Header>
            <div className="columns align-center col-gapless">
              <div className="column col-auto mr-2 mt-1">
                <Styles.Logo style={{ width: 28 }} />
              </div>
              <div className="column">
                <div className="h5">Petis web show</div>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <div className="divider" />
            <div className="py-2">
              <div>
                <NavLink to="/rga" className="btn btn-link">
                  <i className="fas fa-paw" />
                  &nbsp;Consultar RGA
                </NavLink>
              </div>
              <div>
                <NavLink to="/castration" className="btn btn-link">
                  <i className="fas fa-list" />
                  &nbsp;Consultar castração
                </NavLink>
              </div>
              <div>
                <NavLink to="/batch" className="btn btn-link">
                  <i className="fas fa-cubes" />
                  &nbsp;Consultar lote
                </NavLink>
              </div>
              <div>
                <NavLink to="/clinical-care" className="btn btn-link">
                  <i className="fas fa-stethoscope" />
                  &nbsp;Consultar atendimento clínico
                </NavLink>
              </div>
              <div>
                <NavLink to="/adoption-term" className="btn btn-link">
                  <i className="fas fa-file-alt" />
                  &nbsp;Imprimir Termo de Adoção
                </NavLink>
              </div>
            </div>
            <div className="divider" />
          </Card.Body>
          <Card.Footer>
            <div>
              <small>
                <a href="https://petis.me">
                  Petis Tecnologia da Informação LTDA
                </a>
              </small>
              <small className="float-right mt-1">
                v.
                {pack.version}
              </small>
            </div>
          </Card.Footer>
        </Card.Base>
      </div>
    </PageContainer>
  );
};

export default Home;
