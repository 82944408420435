import { AnimalSize } from '~/types';

const translations = {
  toy: 'Toy',
  small: 'Pequeno',
  medium: 'Médio',
  big: 'Grande',
  giant: 'Gigante',
};

export default function animalSize(size: AnimalSize): string {
  return translations[size] || size;
}
