import { AxiosResponse } from 'axios';
import api from './api';

export default {
  show(id: string): Promise<AxiosResponse> {
    return api.get(`/adoption-fairs/${id}`);
  },
  animals(id: string, params: any): Promise<AxiosResponse> {
    return api.get(`/adoption-fairs/${id}/animals`, { params });
  },
  animal(id: string, animalId: number): Promise<AxiosResponse> {
    return api.get(`/adoption-fairs/${id}/animals/${animalId}`);
  },
};
