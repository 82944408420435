import React from 'react';
import animalGender from '~/constants/enumTranslations/animalGender';
import thumbor from '~/lib/thumbor';
import { Animal } from '~/types';

type AnimalShowProps = {
  animal: Animal,
}

const AnimalShow: React.FC<AnimalShowProps> = ({ animal }) => (
  <div>
    <div className="columns align-center">
      <div className="column col-auto">
        <figure className="avatar avatar-xl" data-initial={animal?.name?.[0]}>
          {
            Boolean(animal?.photoURL)
            && (
              <img
                src={thumbor(animal?.photoURL, { width: 64, height: 64 })}
                alt="Avatar do pet"
              />
            )
          }
        </figure>
      </div>
      <div className="column">
        <div className="text-bold">{animal?.name}</div>
        <div>
          {animal?.category?.description}
          {' '}
          -
          {' '}
          {animal?.breed?.description}
        </div>
        <div>{animalGender(animal?.gender)}</div>
        {animal?.chip ? (
          <div>
            Chip:
            {' '}
            {animal?.chip?.uid}
          </div>
        ) : null}
      </div>
    </div>
  </div>
);

export default AnimalShow;
