import React from 'react';

type Prescrition = {
  'id': number
  'title': string
  'data': {
    'name': string
    'dosage': string
  }[]
  'attachments': string[]
}

type PrescritionsProps = {
  prescritions: Prescrition[]
}

const Prescritions = ({ prescritions }: PrescritionsProps) => (
  <>
    {
      prescritions.map((prescrition) => (
        <div key={prescrition.id}>
          <div className="text-bold text-primary">{prescrition.title}</div>
          <div>
            {
              prescrition.data.map((i) => (
                <div key={i.name} className="mb-2">
                  <small>
                    <b>{i.name}</b>
                    <div>
                      <span>{i.dosage}</span>
                    </div>
                  </small>
                </div>
              ))
            }
            <div className="columns">
              {
                (prescrition.attachments || []).map((url) => (
                  <div key={url} className="column col-auto">
                    <a href={url} target="_blank" rel="noreferrer">
                      <img src={url} className="s-rounded" width={80} height={80} alt="Imagem" />
                    </a>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      ))
    }
  </>
);

export default Prescritions;
