import React from 'react';

type RgaBrandProps = {
  position: 'top' | 'bottom';
};

const RgaBrand: React.FC<RgaBrandProps> = ({ position }) => (
  <div
    className="rga-brand-wrapper"
    style={position === 'top' ? { top: 0 } : { bottom: 0 }}
  >
    <div className="rga-brand">
      {position === 'top' ? (
        <div>
          <img src="/images/logo-sm.png" alt="Logo Petis" />
          Petis
        </div>
      ) : (
        'petis.me'
      )}
    </div>
  </div>
);

export default RgaBrand;
