import React from 'react';
import { CastrationEvent } from '~/types';
import TimelineItem from './TimelineItem';

type TimelineProps = {
  events: CastrationEvent[]
  castration: {tutorId: number, clinicId: number, providerId: number}
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Timeline: React.FC<TimelineProps> = ({ events, castration }) => (
  <div>
    {
      events?.map((event, index) => (
        <TimelineItem
          event={event}
          key={event.id}
          last={events.length === index + 1}
          castration={castration}
        />
      ))
    }
  </div>
);

export default Timeline;
