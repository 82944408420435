import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { PageContainer } from '~/components';
import usePageTitle from '~/hooks/usePageTitle';

// import { Container } from './styles';

const NotFound: React.FC<{ title: string }> = ({ title }) => {
  usePageTitle({ title });
  const location = useLocation();
  const { pathname } = location;

  return (
    <PageContainer>
      <div className="full-vh flex-centered">
        <div className="empty" style={{ maxWidth: 350 }}>
          <div className="empty-icon">
            <i className="fas fa-face-frown fa-4x" />
          </div>
          <p className="empty-title h5">404</p>
          <p className="empty-subtitle">
            Não encontramos a página que você está procurando.
          </p>
          <p className="empty-subtitle">
            <small>{pathname}</small>
          </p>
          <div className="empty-action">
            <Link to="/" className="btn btn-primary">
              <i className="fas fa-home" />
              &nbsp;Voltar para a home
            </Link>
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

export default NotFound;
