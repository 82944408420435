export const mapping = {
  created: {
    message: 'A feira está criada mas ainda não começou',
    class: 'text-primary',
    icon: 'fas fa-pause-circle',
  },
  running: {
    message: 'A feira está acontecendo neste momento',
    class: 'text-success fa-fade',
    icon: 'fas fa-play-circle',
  },
  closed: {
    message: 'A feira já acabou',
    class: 'text-error',
    icon: 'fas fa-check-circle',
  },
};
