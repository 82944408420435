/* eslint-disable react/no-array-index-key */
import React from 'react';

type Position = 'top' | 'bottom' | 'right' | 'left';

type RgaPawsProps = {
  position: Position;
};

type RotateOptions = {
  rotateClass: string;
  wrapperStyle?: React.CSSProperties;
};

function getRotateOptions(position: Position): RotateOptions {
  switch (position) {
    case 'top':
      return {
        rotateClass: 'fa-rotate-90 px-1',
        wrapperStyle: {
          top: 0,
          left: 0,
          right: 0,
          height: 20,
          alignItems: 'center',
          lineHeight: '40px',
          textAlign: 'center',
        },
      };
    case 'bottom':
      return {
        rotateClass: 'fa-rotate-270 px-1',
        wrapperStyle: {
          bottom: 0,
          left: 0,
          right: 0,
          height: 20,
          alignItems: 'center',
          lineHeight: '40px',
          textAlign: 'center',
        },
      };
    case 'right':
      return {
        rotateClass: 'fa-rotate-180',
        wrapperStyle: {
          right: 0,
          top: 0,
          bottom: '80%',
          width: 20,
          alignItems: 'center',
          textAlign: 'center',
          flexDirection: 'column',
          display: 'flex',
          justifyContent: 'center',
        },
      };
    case 'left':
      return {
        rotateClass: '',
        wrapperStyle: {
          left: 0,
          top: 10,
          bottom: '70%',
          width: 20,
          alignItems: 'center',
          textAlign: 'center',
          flexDirection: 'column',
        },
      };
    default:
      return { rotateClass: '' };
  }
}

const RgaPaws: React.FC<RgaPawsProps> = ({ position }) => {
  const orientation = ['top', 'bottom'].includes(position)
    ? 'horizontal'
    : 'vertical';
  const arrayFill = Array(orientation === 'horizontal' ? 16 : 11).fill(null);
  const { rotateClass, wrapperStyle } = getRotateOptions(position);

  return (
    <div className="rga-paws-wrapper" style={wrapperStyle}>
      <div className="rga-paws">
        {arrayFill.map((_, index) => (
          <i key={index} className={`fa-solid fa-paw ${rotateClass} fa-xs`} />
        ))}
      </div>
    </div>
  );
};
export default RgaPaws;
