import React from 'react';
import QRCode from 'react-qr-code';
import { useParams } from 'react-router-dom';
import mask from 'slim-mask-js';
import { LoadManager, PageContainer, Styles } from '~/components';
import animalGender from '~/constants/enumTranslations/animalGender';
import animalSize from '~/constants/enumTranslations/animalSize';
import useAxiosRequest from '~/hooks/useAxiosRequest.1';
import usePageTitle from '~/hooks/usePageTitle';
import useQuery from '~/hooks/useQuery';
import dateMask from '~/lib/masks/date';
import thumbor from '~/lib/thumbor';
import rgas from '~/services/rgas';
import RgaBrand from './RgaBrand';
import RgaPaws from './RgaPaws';

const Show: React.FC<{ title: string }> = ({ title }) => {
  const { uid } = useParams() as { uid: string };
  usePageTitle({ title });
  const query = useQuery();

  const { status, error, data } = useAxiosRequest(rgas.animalByUid, uid);

  return (
    <PageContainer>
      <div className="full-header-height flex-centered text-rga">
        <LoadManager style={{ width: '100%' }} apiStatus={status} error={error}>
          <div id="rga">
            <div className="flex-centered pb-2">
              <div className="rga-box">
                <RgaBrand position="top" />
                <RgaPaws position="top" />
                <RgaPaws position="left" />
                <div className="rga-box-body p-2">
                  <div className="h6 columns align-center justify-center">
                    <span className="pr-1 pt-1">
                      <Styles.Logo style={{ width: 20 }} />
                    </span>
                    Registro Geral do Animal - RGA
                  </div>
                  <div className="columns align-center flex-1">
                    <div className="column col-6 text-right">
                      <figure className="mr-2">
                        <QRCode
                          className="ml-2"
                          value={window.location.href}
                          size={88}
                        />
                      </figure>
                    </div>
                    <div className="column col-6 text-left">
                      <figure className="ml-2" data-initial={data?.name?.[0]}>
                        {data?.photoURL ? (
                          <img
                            className="card-rga"
                            src={thumbor(data?.photoURL, {
                              width: 88,
                              height: 88,
                            })}
                            alt="Imagem do Pet"
                          />
                        ) : (
                          <img
                            src="../images/noimage.png"
                            style={{ width: 88, height: 88, borderRadius: 5 }}
                            alt="Animal sem foto"
                          />
                        )}
                      </figure>
                    </div>
                  </div>
                  <div className="flex-0 text-small">
                    <div>
                      <b>Nº do RGA:</b>
                      {' '}
                      {mask(data?.uid, '####.####.####.####.####')}
                    </div>
                    <div>
                      <b>Nome do pet:</b>
                      {' '}
                      {data?.name}
                    </div>
                    {data?.chip ? (
                      <small>
                        <b>Chip:</b>
                        {' '}
                        {data?.chip?.uid}
                      </small>
                    ) : null}
                  </div>
                </div>
                <RgaBrand position="bottom" />
                <RgaPaws position="bottom" />
                <RgaPaws position="right" />
              </div>
            </div>
            <div className="flex-centered mt-2">
              <div className="rga-box">
                <RgaBrand position="top" />
                <RgaPaws position="top" />
                <RgaPaws position="left" />
                <div className="rga-box-body p-2">
                  <div className="h6 columns align-center justify-center pb-1">
                    <span className="pr-1 pt-1">
                      <Styles.Logo style={{ width: 20 }} />
                    </span>
                    Registro Geral do Animal - RGA
                  </div>
                  <div className="text-small">
                    <div className="columns">
                      <div className="column">
                        <b>Espécie:</b>
                      </div>
                      <div className="column col-auto">
                        {data?.category?.description}
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <b>Raça:</b>
                      </div>
                      <div className="column col-auto">
                        {data?.breed?.description}
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <b>Porte:</b>
                      </div>
                      <div className="column col-auto">
                        {animalSize(data?.size)}
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <b>Sexo:</b>
                      </div>
                      <div className="column col-auto">
                        {animalGender(data?.gender)}
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <b>Cor:</b>
                      </div>
                      <div className="column col-auto">{data?.color}</div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <b>Data de nascimento:</b>
                      </div>
                      <div className="column col-auto">
                        {dateMask(data?.bornedAt)}
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <b>É castrado?</b>
                      </div>
                      <div className="column col-auto">
                        {data?.isCastrated ? 'Sim' : 'Não'}
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column col-3">
                        <b>Tutor(a):</b>
                      </div>
                      <div className="column text-right text-ellipsis">
                        {data?.tutor?.fullName}
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <b>Emitido em:</b>
                      </div>
                      <div className="column col-auto">
                        {dateMask(data?.createdAt, {
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <RgaPaws position="bottom" />
                <RgaBrand position="bottom" />
                <RgaPaws position="right" />
              </div>
            </div>
            {query?.hideNavbar ? null : (
              <div className="flex-centered mt-2 pt-2 no-print">
                <button
                  onClick={() => window.print()}
                  type="button"
                  className="btn btn-primary btn-sm btn-block"
                  data-tooltip="Imprimir"
                  style={{
                    maxWidth: 400,
                    minWidth: 350,
                    width: '60%',
                    right: 100,
                    backgroundColor: '#f28914',
                  }}
                >
                  <i className="fas fa-print" />
                  &nbsp;Imprimir
                </button>
              </div>
            )}
          </div>
        </LoadManager>
      </div>
    </PageContainer>
  );
};

export default Show;
