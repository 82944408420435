import React from 'react';
import { useParams } from 'react-router-dom';
import mask from 'slim-mask-js';
import castrationStatus from '~/constants/enumTranslations/castrationStatus';
import useAxiosRequest from '~/hooks/useAxiosRequest.1';
import usePageTitle from '~/hooks/usePageTitle';
import dateMask from '~/lib/masks/date';
import castrations from '~/services/castrations';
import { LoadManager, PageContainer, PersonShow } from '~/components';
import QRCode from 'react-qr-code';
import thumbor from '~/lib/thumbor';
import Comments from '~/views/Castration/Show/Comments';
import AnimalShow from './AnimalShow';
import Timeline from './Timeline';

const Show: React.FC<{ title: string }> = ({ title }) => {
  usePageTitle({ title });
  const { uid } = useParams();

  const { status, error, data } = useAxiosRequest(castrations.castrationByUid, uid);

  const {
    animal, tutor, events, provider, clinic, comments, ...castration
  } = data || {};

  return (
    <PageContainer>
      <LoadManager apiStatus={status} error={error}>
        <>
          <div className="py-2">
            <h5 className="h5">Detalhes da castração</h5>
            <div className="columns align-center">
              <div className="column col-auto mt-2">
                <QRCode value={window.location.href} size={80} />
              </div>
              <div className="column col-xs-12">
                <div className="columns my-1">
                  <div className="column col-xs-12">
                    <small>
                      Código da castração:
                      {' '}
                      <b>{mask(castration?.uid, '####.####.####.####.####')}</b>
                    </small>
                  </div>
                  <div className="column col-xs-12 col-auto">
                    <small>
                      Status da castração:
                      {' '}
                      <b className={`castration-status ${castration?.status}`}>{castrationStatus(castration?.status)}</b>
                    </small>
                  </div>
                </div>
                <div className="columns my-1">
                  <div className="column col-xs-12">
                    <small>
                      Data da solicitação:
                      {' '}
                      <b>{dateMask(castration?.createdAt, { hour: '2-digit', minute: '2-digit' })}</b>
                    </small>
                  </div>
                  <div className="column col-xs-12 col-auto text-ellipsis">
                    <small>
                      Solicitante:
                      {' '}
                      <b>{tutor?.fullName}</b>
                    </small>
                  </div>
                </div>
                <div className="columns my-1">
                  <div className="column col-xs-12">
                    <small>
                      Data da aprovação:
                      {' '}
                      <b>{dateMask(castration?.approvedAt, { hour: '2-digit', minute: '2-digit' })}</b>
                    </small>
                  </div>
                  <div className="column col-xs-12 col-auto text-ellipsis">
                    <small>
                      Data de execução:
                      {' '}
                      <b>{dateMask(castration?.performedAt, { hour: '2-digit', minute: '2-digit' })}</b>
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="divider" />
          <div className="py-2">
            <h5 className="h5">Dados do Solicitante</h5>
            <div className="columns align-center">
              <div className="column col-auto">
                <figure className="avatar avatar-xl" data-initial={tutor?.fullName?.[0]}>
                  {
                  Boolean(tutor?.photoURL)
                  && (
                    <img
                      src={thumbor(tutor?.photoURL, { width: 64, height: 64 })}
                      alt="Avatar da pessoa"
                    />
                  )
                }
                </figure>
              </div>
              <div className="column">
                <b>{tutor?.fullName}</b>
                <br />
                {tutor?.description}
              </div>
            </div>
          </div>
          <div className="divider" />
          <div className="py-2">
            <h5 className="h5">Dados do Pet</h5>
            <AnimalShow animal={animal} />
          </div>

          <div className="divider" />
          <div className="py-2">
            <h5 className="h5">Dados do Fornecedor</h5>
            <PersonShow person={provider} />
          </div>

          <div className="divider" />
          <div className="py-2">
            <h5 className="h5">Dados da Clínica</h5>
            <PersonShow
              person={clinic}
              fallbackComponent={(
                <div>
                  Ainda não selecionamos nenhuma clínica para realizar esta castração.
                </div>
              )}
            />
          </div>
          <div className="divider" />
          <div className="py-2">
            <h5 className="h5">Eventos da castração</h5>
            <Timeline events={events} castration={castration} />
          </div>
          {
            Boolean(comments?.length) && (
              <>
                <div className="divider" />
                <div className="py-2">
                  <h5 className="h5">Comentários</h5>
                  <Comments comments={comments} />
                </div>
              </>
            )
          }
        </>
      </LoadManager>
    </PageContainer>
  );
};

export default Show;
