import { ClinicalCareStatus } from '~/types';

const translations = {
  screening: 'Triagem',
  forwarded: 'Encaminhado',
  hospitalized: 'Internado',
  medicalRelease: 'Alta médica',
  death: 'Falecido',
  pullout: 'Em retirada',
  caught: 'Saída',
  done: 'Concluído',
  canceled: 'Cancelado',
};

export default function clinicalCareStatus(status: ClinicalCareStatus): string {
  return translations[status] || status;
}
