import React from 'react';
import QRCode from 'react-qr-code';
import { useParams } from 'react-router-dom';
import {
  LoadManager, PageContainer, Share,
} from '~/components';
import animalGender from '~/constants/enumTranslations/animalGender';
import animalSize from '~/constants/enumTranslations/animalSize';
import useAxiosRequest from '~/hooks/useAxiosRequest.1';
import usePageTitle from '~/hooks/usePageTitle';
import dateMask from '~/lib/masks/date';
import thumbor from '~/lib/thumbor';
import rgas from '~/services/rgas';
import { Header, Sticky } from '~/views/AdoptionFair/Show/styles';

const Show: React.FC<{ title: string }> = ({ title }) => {
  usePageTitle({ title });
  const { uid } = useParams();

  const { data, status, error } = useAxiosRequest(rgas.animalByUid, uid);

  return (
    <PageContainer>
      <LoadManager apiStatus={status} error={error}>
        {
          Boolean(data) && (
            <>
              <Header>
                <div className="column col-auto">
                  <figure className="avatar avatar-xl" data-initial={data?.name?.[0]}>
                    {
                      Boolean(data?.photoURL)
                      && (
                        <img
                          src={thumbor(data?.photoURL, { width: 64, height: 64 })}
                          alt="Avatar do pet"
                        />
                      )
                    }
                  </figure>
                </div>
                <div className="column">
                  <span className="h5">{data.name}</span>
                  <div>
                    <small>
                      {data.category?.description}
                      {' '}
                      -
                      {' '}
                      {data.breed?.description}
                    </small>
                  </div>
                </div>
              </Header>
              <div className="py-2">
                <div className="columns">
                  <div className="column">Porte</div>
                  <div className="column col-auto">
                    <b>{animalSize(data?.size)}</b>
                  </div>
                </div>
                <div className="columns">
                  <div className="column">Sexo</div>
                  <div className="column col-auto">
                    <b>{animalGender(data.gender)}</b>
                  </div>
                </div>
                <div className="columns">
                  <div className="column">Nascimento</div>
                  <div className="column col-auto">
                    <b>{dateMask(data.bornedAt)}</b>
                  </div>
                </div>
                <div className="columns">
                  <div className="column">Tutor</div>
                  <div className="column col-auto">
                    <b>{data?.tutor?.fullName}</b>
                  </div>
                </div>
              </div>
              <div className="divider" />
              <div className="py-2">
                <span className="h5">Baixe o aplicativo</span>
                <div>
                  <small>
                    O Petis está disponível nas principais lojas de aplicativos, baixe agora!
                  </small>
                </div>
                <div className="columns align-center pt-2">
                  <div className="column col-auto pt-2">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.paliari.petis"
                      target="_blank"
                      className="btn"
                      style={{ minWidth: '120px' }}
                      rel="noreferrer"
                    >
                      <i className="fa-brands fa-android fa-lg mr-1" />
                      Android
                    </a>
                  </div>
                  <div className="column col-auto pt-2">
                    <a
                      href="https://itunes.apple.com/br/app/petis/id1413161923?mt=8"
                      target="_blank"
                      className="btn"
                      style={{ minWidth: '120px' }}
                      rel="noreferrer"
                    >
                      <i className="fa-brands fa-apple fa-lg mr-1" />
                      iOS
                    </a>
                  </div>
                </div>
              </div>
              <div className="divider" />
              <div className="py-2">
                <div className="">
                  <span className="h5">Adotar este pet</span>
                  <div className="pb-2">
                    <small className="text-primary">
                      As solicitações de adoção são feitas exclusivamente
                      através do aplicativo Petis.
                    </small>
                  </div>
                </div>
                <div className="columns align-center">
                  <div className="column col-auto">
                    <QRCode value={window.location.href} size={80} />
                  </div>
                  <div className="column col-xs-12">
                    <div className="columns my-1">
                      <div className="column col-xs-12">
                        <small>
                          Escaneie o QRCode para solicitar a adoção deste pet no aplicativo.
                        </small>
                      </div>
                    </div>
                    <Sticky>
                      <div className="my-2">
                        <div className="columns">
                          <div className="column col-auto align-center">
                            <small>ou compartilhe o link de solicitação de adoção do pet:</small>
                          </div>
                          <div className="column">
                            <Share
                              url={window.location.href}
                              title={`Solicite agora mesmo a adoção do pet "${data?.name}" no aplicativo Petis.\n\n`}
                            />
                          </div>
                        </div>
                      </div>
                    </Sticky>
                  </div>
                </div>
              </div>
            </>
          )
        }
      </LoadManager>
    </PageContainer>
  );
};

export default Show;
