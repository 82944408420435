import React from 'react';
import QRCode from 'react-qr-code';
import { Link, useParams } from 'react-router-dom';
import mask from 'slim-mask-js';
import {
  LoadManager, PageContainer, PersonShow,
} from '~/components';
import batchStatus from '~/constants/enumTranslations/batchStatus';
import useAxiosRequest from '~/hooks/useAxiosRequest.1';
import usePageTitle from '~/hooks/usePageTitle';
import dateMask from '~/lib/masks/date';
import thumbor from '~/lib/thumbor';
import batches from '~/services/batches';
import { Castration } from '~/types';

const Show: React.FC<{ title: string }> = ({ title }) => {
  usePageTitle({ title });
  const { uid } = useParams();

  const { status, error, data } = useAxiosRequest(batches.batchByUid, uid);

  const { person, provider, ...batch } = data || {};

  return (
    <PageContainer>
      <LoadManager apiStatus={status} error={error}>
        <div className="py-2">
          <h5 className="h5">Detalhes do lote</h5>
          <div className="columns align-center">
            <div className="column col-auto mt-2">
              <QRCode value={window.location.href} size={80} />
            </div>
            <div className="column col-xs-12">
              <div className="columns my-1">
                <div className="column col-xs-12">
                  <small>
                    Código do lote:
                    {' '}
                    <b>{mask(batch?.uid, '####.####.####.####.####')}</b>
                  </small>
                </div>
                <div className="column col-auto col-xs-12">
                  <small>
                    Status do lote:
                    {' '}
                    <b className={`batch-status ${batch?.status}`}>{batchStatus(batch?.status)}</b>
                  </small>
                </div>
              </div>
              <div className="columns my-1">
                <div className="column col-xs-12">
                  <small>
                    Data da solicitação:
                    {' '}
                    <b>{dateMask(batch?.createdAt, { hour: '2-digit', minute: '2-digit' })}</b>
                  </small>
                </div>

                <div className="column col-auto text-ellipsis col-xs-12">
                  <small>
                    Quantidade no lote:
                    {' '}
                    <b>{batch?.amount}</b>
                  </small>
                </div>
              </div>
            </div>
          </div>

          <div className="divider" />
          <div className="py-2">
            <h5 className="h5">Dados do Fornecedor</h5>
            <PersonShow person={provider} />
          </div>

          <div className="divider" />
          <div className="py-2">
            <h5 className="h5">Dados da Clínica</h5>
            <PersonShow person={person} />
          </div>
          {
              batch?.castrations?.length > 0 && (
                <>
                  <div className="divider" />
                  <div className="py-2">
                    <h5 className="ht">Castrações no lote</h5>
                    <table className="table table-sm text-small">
                      <thead>
                        <tr>
                          <th>Pet</th>
                          <th>Código</th>
                          <th>Tutor</th>
                          <th style={{ width: 160 }}>Solicitado em</th>
                          <th className="no-print text-right">{}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          batch?.castrations?.map(
                            (castration: Castration) => (
                              <tr key={castration.uid}>
                                <td>
                                  <figure className="avatar avatar-xs" data-initial={castration?.animal?.name?.[0]}>
                                    {
                                      Boolean(castration?.animal?.photoURL)
                                      && (
                                        <img
                                          src={
                                            thumbor(
                                              castration?.animal?.photoURL,
                                              { width: 48, height: 48 },
                                            )
                                          }
                                          alt="Avatar do pet"
                                        />
                                      )
                                    }
                                  </figure>
                                  <span className="ml-2"><small>{castration?.animal?.name}</small></span>
                                </td>
                                <td><small>{mask(castration?.uid, '####.####.####.####.####')}</small></td>
                                <td className="text-ellipsis">
                                  <figure className="avatar avatar-xs" data-initial={castration?.tutor?.fullName?.[0]}>
                                    {
                                      Boolean(castration?.tutor?.photoURL)
                                      && (
                                        <img
                                          src={
                                            thumbor(
                                              castration?.tutor?.photoURL,
                                              { width: 48, height: 48 },
                                            )
                                          }
                                          alt="Avatar do tutor"
                                        />
                                      )
                                    }
                                  </figure>
                                  <span className="ml-2"><small>{castration?.tutor?.fullName}</small></span>
                                </td>
                                <td><small>{dateMask(castration?.createdAt, { minute: '2-digit', hour: '2-digit' })}</small></td>
                                <td className="text-right no-print">
                                  <Link to={`/castration/${castration?.uid}`}>
                                    <small><i className="fas fa-eye" /></small>
                                  </Link>
                                </td>
                              </tr>
                            ),
                          )
                        }
                      </tbody>
                    </table>
                  </div>
                </>
              )
            }
        </div>
      </LoadManager>
    </PageContainer>
  );
};

export default Show;
