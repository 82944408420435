import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import mask from 'slim-mask-js';
import { Card, LoadManager, Paginator } from '~/components';
import animalGender from '~/constants/enumTranslations/animalGender';
import useAxiosRequest from '~/hooks/useAxiosRequest.1';
import useQuery from '~/hooks/useQuery';
import dateMask from '~/lib/masks/date';
import thumbor from '~/lib/thumbor';
import adoptionFairs from '~/services/adoptionFairs';

// import { Container } from './styles';

const Animals = ({ id }: { id: string }) => {
  const query = useQuery();
  const { data, status, error } = useAxiosRequest(adoptionFairs.animals, id, query);

  return (
    <LoadManager apiStatus={status} error={error}>
      {
        Boolean(data) && (
          <>
            <div className="columns">
              {
                data.rows.map((animalFair: any) => (
                  <div key={animalFair.id} className="column col-6 col-sm-12 my-2">
                    <Card.Base>
                      <Card.Body>
                        <div className="columns align-center col-gapless">
                          <div className="column col-auto">
                            <div className="column col-auto">
                              <figure className="avatar avatar-lg" data-initial={animalFair?.animal?.name?.[0]}>
                                {
                                  Boolean(animalFair?.animal?.photoURL)
                                  && (
                                    <img
                                      src={
                                        thumbor(
                                          animalFair?.animal?.photoURL,
                                          { width: 64, height: 64 },
                                        )
                                      }
                                      alt="Avatar do pet"
                                    />
                                  )
                                }
                              </figure>
                            </div>
                          </div>
                          <div className="column">
                            <div>{animalFair.animal.name}</div>
                            <div>
                              <small>
                                {animalFair.animal.category.description}
                                {' '}
                                -
                                &nbsp;
                                {animalFair.animal.breed.description}
                              </small>
                            </div>
                          </div>
                        </div>
                        <div className="divider" />
                        <div className="columns">
                          <div className="column">RGA</div>
                          <div className="column col-auto">
                            <b>{mask(animalFair.animal.uid, '####.####.####.####.####')}</b>
                          </div>
                        </div>
                        <div className="columns">
                          <div className="column">Sexo</div>
                          <div className="column col-auto">
                            <b>{animalGender(animalFair.animal.gender)}</b>
                          </div>
                        </div>
                        <div className="columns">
                          <div className="column">Nascimento</div>
                          <div className="column col-auto">
                            <b>{dateMask(animalFair.animal.bornedAt)}</b>
                          </div>
                        </div>
                        <div className="columns">
                          <div className="column">Adicionado em</div>
                          <div className="column col-auto">
                            <b>{dateMask(animalFair.createdAt, { hour: '2-digit', minute: '2-digit' })}</b>
                          </div>
                        </div>
                        <div className="columns">
                          <div className="column">Adotado?</div>
                          <div className="column col-auto">
                            <b>{animalFair.adopted ? 'Sim' : 'Não'}</b>
                          </div>
                        </div>
                      </Card.Body>
                      <Card.Footer>
                        <div className="text-right">
                          <Link className="btn btn-link" to={`/adoption-fairs/${id}/animals/${animalFair.id}`}>
                            <i className="fas fa-link" />
                            &nbsp;Abrir
                          </Link>
                        </div>
                      </Card.Footer>
                    </Card.Base>
                  </div>
                ))
              }
            </div>
            <Paginator paginatedList={data} />
            <Outlet />
          </>
        )
      }
    </LoadManager>
  );
};

export default Animals;
