import { AxiosResponse } from 'axios';
import { useCallback, useLayoutEffect, useState } from 'react';
import { ApiError } from '~/services/api/interceptors';
import { API_STATUS } from './useAxiosRequest';

export default function useAxiosRequest(asyncFunction: any, ...args: any[]) {
  const [status, setStatus] = useState<API_STATUS>();
  const [data, setData] = useState<any>();
  const [error, setError] = useState<ApiError>();

  const loading = status === API_STATUS.LOADING;

  const fetchData = useCallback(() => {
    if (status === API_STATUS.LOADING) return;
    setStatus(API_STATUS.LOADING);
    asyncFunction(...args)
      .then((res: AxiosResponse) => {
        setData(res.data);
        setStatus(API_STATUS.DONE);
      })
      .catch((e: ApiError) => {
        setError(e);
        setStatus(API_STATUS.ERROR);
      });
  }, [args, asyncFunction, status]);

  useLayoutEffect(() => {
    if (!loading) fetchData();
  }, [...args]);

  return {
    status,
    data,
    error,
    setData,
    loading,
    asyncFunction,
    fetchData,
  };
}
