import React from 'react';
import dateMask from '~/lib/masks/date';

type Record = {
  'id': number
  'createdAt': string
  'description': string
  'attachments': string[]
}

type RecordsProps = {
  records: Record[]
}

const Records = ({ records }: RecordsProps) => (
  <>
    {
      records.map((record) => (
        <div key={record.id}>
          <div>
            {dateMask(record.createdAt, { hour: '2-digit', minute: '2-digit' })}
            {' '}
            -
            {' '}
            {record.description}
          </div>
          <div className="columns">
            {
              record.attachments.map((url) => (
                <div key={url} className="column col-auto">
                  <a href={url} target="_blank" rel="noreferrer">
                    <img src={url} className="s-rounded" width={80} height={80} alt="Imagem" />
                  </a>
                </div>
              ))
            }
          </div>
        </div>
      ))
    }
  </>
);

export default Records;
