import React from 'react';

type InfoProps = {
  label: string
  value?: string
}
const Info: React.FC<InfoProps> = ({ label, value }) => (
  <div className="columns">
    <div className="column">{label}</div>
    <div className="column col-auto text-ellipsis"><b>{value}</b></div>
  </div>
);

Info.defaultProps = {
  value: undefined,
};

export default Info;
