import { jsEncode } from 'paliari-js-utils';

const subdomain = window.location.hostname.split('.')[0] as 'web-show-staging' | 'development' | 'web-show';

type Environment = {
  REACT_APP_BASE_API_URL: string
  REACT_APP_BASE_AUTH_URL: string
  REACT_APP_GOOGLE_MAPS_API_KEY: string
  REACT_THUMBOR_BASE_URL: string
  REACT_THUMBOR_SECURITY_KEY: string
}

type EnvironmentObject = {
  'web-show-staging': Environment
  development: Environment
  'web-show': Environment
}

const environment: EnvironmentObject = {
  'web-show-staging': {
    REACT_APP_BASE_API_URL: 'https://api-staging.petis.me',
    REACT_APP_BASE_AUTH_URL: 'https://api-staging.petis.me/auth',
    REACT_APP_GOOGLE_MAPS_API_KEY: 'AIzaSyCKGJyEvGZNf4xyDvQ3br7hZ5J9apqDO8A',
    REACT_THUMBOR_BASE_URL: 'https://thumb.paliari.com.br',
    REACT_THUMBOR_SECURITY_KEY: jsEncode('ffrariCCiiXaViaVlslrXBfYEaaXXaX8'),
  },
  development: {
    REACT_APP_BASE_API_URL: process.env.REACT_APP_BASE_API_URL || 'http://localhost:4000',
    REACT_APP_BASE_AUTH_URL: process.env.REACT_APP_BASE_AUTH_URL || 'http://localhost:4000/auth',
    REACT_APP_GOOGLE_MAPS_API_KEY: 'AIzaSyCKGJyEvGZNf4xyDvQ3br7hZ5J9apqDO8A',
    REACT_THUMBOR_BASE_URL: 'https://thumb.paliari.com.br',
    REACT_THUMBOR_SECURITY_KEY: jsEncode('ffrariCCiiXaViaVlslrXBfYEaaXXaX8'),
  },
  'web-show': {
    REACT_APP_BASE_API_URL: 'https://api.petis.me',
    REACT_APP_BASE_AUTH_URL: 'https://api.petis.me/auth',
    REACT_APP_GOOGLE_MAPS_API_KEY: 'AIzaSyCKGJyEvGZNf4xyDvQ3br7hZ5J9apqDO8A',
    REACT_THUMBOR_BASE_URL: 'https://thumb.paliari.com.br',
    REACT_THUMBOR_SECURITY_KEY: jsEncode('ffrariCCiiXaViaVlslrXBfYEaaXXaX8'),
  },
};

export default environment[subdomain] || environment.development as Environment;
