import { AxiosResponse } from 'axios';
import api from './api';

export default {
  index(uid: string): Promise<AxiosResponse> {
    return api.get(`/adoption-term/${uid}`);
  },
  show(uid: string, fairId: string): Promise<AxiosResponse> {
    return api.get(`/adoption-term/${uid}/${fairId}`);
  },
};
