import { BatchStatus } from '~/types';

const translations = {
  requested: 'Solicitado',
  rejected: 'Rejeitado',
  approved: 'Aprovado',
};

export default function batchStatus(status: BatchStatus): string {
  return translations[status] || status;
}
