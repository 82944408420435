import React from 'react';
import documentNumberMask from '~/lib/masks/documentNumber';
import thumbor from '~/lib/thumbor';
import { Person } from '~/types';

type ProviderProps = {
  provider: Person
}
const Provider: React.FC<ProviderProps> = ({ provider }) => (
  <div>
    <div className="card-body">
      <div className="columns align-center">
        <div className="column col-auto">
          <figure className="avatar avatar-xl" data-initial={provider?.fullName?.[0]}>
            {
              Boolean(provider?.photoURL)
              && (
                <img
                  src={thumbor(provider?.photoURL, { width: 64, height: 64 })}
                  alt="Avatar da pessoa"
                />
              )
            }
          </figure>
        </div>
        <div className="column text-ellipsis">
          <div className="text-bold">{provider?.fullName}</div>
          <div className="text-ellipsis">{provider?.email}</div>
          <div>{documentNumberMask(provider?.documentNumber)}</div>
        </div>
      </div>
    </div>
  </div>
);

export default Provider;
