/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import mask from 'slim-mask-js';
import { LoadManager, Share } from '~/components';
import animalGender from '~/constants/enumTranslations/animalGender';
import useAxiosRequest from '~/hooks/useAxiosRequest.1';
import usePageTitle from '~/hooks/usePageTitle';
import dateMask from '~/lib/masks/date';
import thumbor from '~/lib/thumbor';
import adoptionFairs from '~/services/adoptionFairs';
import QRCode from 'react-qr-code';

const Animal: React.FC<{ title: string }> = ({ title }) => {
  usePageTitle({ title });
  const navigate = useNavigate();
  const { id, animalId } = useParams();

  const { data, status, error } = useAxiosRequest(adoptionFairs.animal, id, animalId);

  function close() {
    navigate(`/adoption-fairs/${id}`);
  }

  return (
    <div className="modal active" id="modal-id">
      <a onClick={close} className="modal-overlay" aria-label="Close" />
      <div className="modal-container">
        <div className="modal-header">
          <a onClick={close} className="btn btn-clear float-right" aria-label="Close" />
          <div className="modal-title h5">Pet</div>
        </div>
        <div className="modal-body">
          <div className="content">
            <LoadManager apiStatus={status} error={error}>
              {Boolean(data) && (
                <>
                  <div className="columns align-center col-gapless mb-2">
                    <div className="column col-auto">
                      <div className="column col-auto">
                        <figure className="avatar avatar-lg" data-initial={data?.animal?.name?.[0]}>
                          {
                            Boolean(data?.animal?.photoURL)
                            && (
                              <img
                                src={
                                  thumbor(
                                    data?.animal?.photoURL,
                                    { width: 64, height: 64 },
                                  )
                                }
                                alt="Avatar do pet"
                              />
                            )
                          }
                        </figure>
                      </div>
                    </div>
                    <div className="column">
                      <div>{data.animal.name}</div>
                      <div>
                        <small>
                          {data.animal.category.description}
                          {' '}
                          -
                          &nbsp;
                          {data.animal.breed.description}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="divider" />
                  <div className="columns py-2 align-center">
                    <div className="column col-auto flex-centered">
                      <QRCode value={window.location.href} style={{ width: 110, height: 110 }} />
                    </div>
                    <div className="column">
                      <div className="columns">
                        <div className="column">RGA</div>
                        <div className="column col-auto">
                          <b>{mask(data.animal.uid, '####.####.####.####.####')}</b>
                        </div>
                      </div>
                      <div className="columns">
                        <div className="column">Sexo</div>
                        <div className="column col-auto">
                          <b>{animalGender(data.animal.gender)}</b>
                        </div>
                      </div>
                      <div className="columns">
                        <div className="column">Nascimento</div>
                        <div className="column col-auto">
                          <b>{dateMask(data.animal.bornedAt)}</b>
                        </div>
                      </div>
                      <div className="columns">
                        <div className="column">Adicionado em</div>
                        <div className="column col-auto">
                          <b>{dateMask(data.createdAt, { hour: '2-digit', minute: '2-digit' })}</b>
                        </div>
                      </div>
                      <div className="columns">
                        <div className="column">Adotado?</div>
                        <div className="column col-auto">
                          <b>{data.adopted ? 'Sim' : 'Não'}</b>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="divider" />
                  <div className="columns mt-2">
                    <div className="column col-auto align-center">
                      <small>Compartilhar pet:</small>
                    </div>
                    <div className="column">
                      <Share url={window.location.href} title={`Venha ver o pet ${data?.animal?.name} na feirinha de adoção no Petis`} />
                    </div>
                  </div>
                </>
              )}
            </LoadManager>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Animal;
