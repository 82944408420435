import React from 'react';
import { useParams } from 'react-router-dom';
import {
  LoadManager, PageContainer,
} from '~/components';
import useAxiosRequest from '~/hooks/useAxiosRequest.1';
import usePageTitle from '~/hooks/usePageTitle';
import adoptionTerm from '~/services/adoptionTerm';
import dateMask from '~/lib/masks/date';
import NewTutor from './NewTutor';
import AnimalShow from './AnimalShow';
import PersonShow from './PersonShow';

const AdoptionTermShow: React.FC<{ title: string }> = ({ title }) => {
  usePageTitle({ title });
  const { uid } = useParams();
  const { fairId } = useParams();

  const { status, error, data } = useAxiosRequest(adoptionTerm.show, uid, fairId);

  return (
    <PageContainer>
      <LoadManager apiStatus={status} error={error}>
        <div className="py-2">
          <div className="flex-centered pb-2 mb-2 no-print" style={{ justifyContent: 'flex-end' }}>
            <button
              onClick={() => window.print()}
              type="button"
              className="btn btn-primary btn-sm btn-block"
              data-tooltip="Imprimir"
              style={{
                minWidth: 150,
                width: '10%',
                backgroundColor: '#f28914',
              }}
            >
              <i className="fas fa-print" />
              &nbsp;Imprimir
            </button>
          </div>
          <small>
            <div className="text-center text-bold text-uppercase pb-2">
              <u>
                Termo de adoção nº&nbsp;
                {data?.animalFair?.adoptionId}
              </u>
            </div>
            <div className="columns align-center pb-2 mb-2">
              {data?.animalFair?.fair?.terms}
            </div>
            <div className="text-right pb-2 mb-2">
              <b>Local da Feira:&nbsp;</b>
              {data?.animalFair?.fair?.address?.formattedAddress}
            </div>
            <div className="divider pb-2" />
            <div>
              <div className="text-center text-bold text-uppercase pb-2 mb-2">
                Dados do adotante
              </div>
              <NewTutor newTutor={data?.animalFair?.adoption?.newTutor} />
            </div>
            <div className="divider pb-2" />
            <div>
              <div className="text-center text-bold text-uppercase pb-2 mb-2">
                Dados do pet
              </div>
              <AnimalShow animal={data?.animalFair?.animal} />
            </div>
            <div className="divider pb-2" />
            <div>
              <div className="text-center text-bold text-uppercase pb-2 mb-2">
                Dados do doador
              </div>
              <PersonShow person={data?.animalFair?.person} />
            </div>
            <div className="divider pb-2" />
            <div className="signature">
              <div className="columns align-center text-center pt-2 mt-2">
                <div className="column">
                  <div>{data?.animalFair?.adoption?.newTutor?.fullName}</div>
                  <div>{dateMask(data?.animalFair?.adoption?.adoptedAt)}</div>
                  <div><b>Adotante</b></div>
                </div>
                <div className="column">
                  <div>{data?.animalFair?.person?.fullName}</div>
                  <div>{dateMask(data?.animalFair?.createdAt)}</div>
                  <div><b>Doador</b></div>
                </div>
              </div>
            </div>
            <div className="text-center">
              {data?.animalFair?.fair?.address?.city?.name}
              ,&nbsp;
              {dateMask(data?.animalFair?.adoption?.adoptedAt, { year: 'numeric', month: 'long', day: 'numeric' })}
              .
            </div>
          </small>
        </div>
      </LoadManager>
    </PageContainer>
  );
};

export default AdoptionTermShow;
