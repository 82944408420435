import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, LoadManager, PageContainer } from '~/components';
import useAxiosRequest from '~/hooks/useAxiosRequest.1';
import usePageTitle from '~/hooks/usePageTitle';
import dateMask from '~/lib/masks/date';
import AdoptionStatus from '~/constants/enumTranslations/adoptionStatus';
import adoptionTerm from '~/services/adoptionTerm';
import thumbor from '~/lib/thumbor';
import animalGender from '~/constants/enumTranslations/animalGender';

const AdoptionTermList: React.FC<{ title: string }> = ({ title }) => {
  usePageTitle({ title });
  const { uid } = useParams();

  const { status, error, data } = useAxiosRequest(adoptionTerm.index, uid);

  return (
    <PageContainer>
      <LoadManager apiStatus={status} error={error}>
        <div className="py-2">
          <div className="columns align-center pb-2 mb-2">
            <div className="column col-auto">
              <figure className="avatar avatar-xl" data-initial={data?.animal?.name?.[0]}>
                {
                  Boolean(data?.animal?.photoURL)
                  && (
                    <img
                      src={thumbor(data?.animal?.photoURL, { width: 64, height: 64 })}
                      alt="Avatar do pet"
                    />
                  )
                }
              </figure>
            </div>
            <div className="column">
              <div className="text-bold">{data?.animal?.name}</div>
              <div>
                {data?.animal?.category?.description}
                {' '}
                -
                {' '}
                {data?.animal?.breed?.description}
              </div>
              <div>{animalGender(data?.animal?.gender)}</div>
              {data?.animal?.chip ? (
                <div>
                  Chip:
                  {' '}
                  {data?.animal?.chip?.uid}
                </div>
              ) : null}
            </div>
          </div>
          <div className="h5 pt-2 mt-2">
            &nbsp;Histórico de Feiras do Pet
          </div>
          <div className="divider pb-2" />
          {
            data?.result?.map((termData: any) => (
              <div className="my-2" key={termData?.id}>
                <Card.Base>
                  <Card.Header>
                    <div className="columns">
                      <div className="column">
                        <div className="h6 card-title">
                          <i className="fas fa-map-marker-alt" />
                          &nbsp;
                          {termData?.fair?.title}
                        </div>
                      </div>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <small>
                      <div className="columns">
                        <div className="column">
                          <div>
                            <small>Adotado por</small>
                            <div className="d-flex align-center mt-1">
                              <div className="ml-1" style={{ lineHeight: 1 }}>
                                <div className="text-bold">
                                  {
                                    termData?.adoption
                                      ? termData?.adoption?.newTutor?.fullName
                                      : '-'
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="column">
                          <div>
                            <small>Data da adoção</small>
                            <div className="text-bold">
                              {dateMask(termData?.adoption?.adoptedAt, { hour: '2-digit', minute: '2-digit' })}
                            </div>
                          </div>
                        </div>
                        <div className="column">
                          <div>
                            <small>Status da adoção</small>
                            {
                              termData?.adoption ? (
                                <div className={`adoption-status ${termData?.adoption?.status}`}>
                                  {AdoptionStatus(termData?.adoption?.status)}
                                </div>
                              ) : (
                                <div className="text-bold">Adoção não solicitada</div>
                              )
                            }
                          </div>
                        </div>
                      </div>
                      <div className="divider" />
                      <div className="columns">
                        <div className="column">
                          <div>
                            <small>Doado por</small>
                            <div className="text-bold">{termData?.person?.fullName}</div>
                          </div>
                        </div>
                        <div className="column">
                          <div>
                            <small>Adicionado a feira em</small>
                            <div className="text-bold">
                              {dateMask(termData?.createdAt, { hour: '2-digit', minute: '2-digit' })}
                            </div>
                          </div>
                        </div>
                        <div className="column">
                          <div>
                            <div>
                              {
                                termData?.adoption ? (
                                  <div>
                                    <div>
                                      <small>Termo de adoção</small>
                                    </div>
                                    <Link to={`/adoption-term/${data?.animal?.uid}/${termData?.fairId}`}>
                                      <i className="fas fa-print" />
                                      &nbsp;Imprimir
                                    </Link>
                                  </div>
                                ) : null
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </small>
                  </Card.Body>
                </Card.Base>
              </div>
            ))
          }
        </div>
      </LoadManager>
    </PageContainer>
  );
};

export default AdoptionTermList;
