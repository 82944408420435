import React from 'react';
import documentNumberMask from '~/lib/masks/documentNumber';
import thumbor from '~/lib/thumbor';
import { Person } from '~/types';

type PersonCardProps = {
  fallbackComponent?: React.ReactNode
  person?: Person
}
const PersonShow: React.FC<PersonCardProps> = ({ fallbackComponent, person }) => (
  <div>
    <div className="card-body">
      {
        person ? (
          <div className="columns align-center">
            <div className="column col-auto">
              <figure className="avatar avatar-xl" data-initial={person?.fullName?.[0]}>
                {
                  Boolean(person?.photoURL)
                  && (
                    <img
                      src={thumbor(person?.photoURL, { width: 64, height: 64 })}
                      alt="Avatar da pessoa"
                    />
                  )
                }
              </figure>
            </div>
            <div className="column text-ellipsis">
              <div className="text-bold">{person?.fullName}</div>
              <div className="text-ellipsis">{person?.email}</div>
              <div>{documentNumberMask(person?.documentNumber)}</div>
            </div>
          </div>
        )
          : fallbackComponent
      }
    </div>
  </div>
);

PersonShow.defaultProps = {
  fallbackComponent: null,
  person: undefined,
};

export default PersonShow;
