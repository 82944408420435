import React from 'react';
import { ClinicalCareEvent } from '~/types';
import TimelineItem from './TimelineItem';

type TimelineProps = {
  events: ClinicalCareEvent[]
  clinicalCare: { tutorId?: number, clinicId: number, authorId: number, providerId: number }
}

const Timeline: React.FC<TimelineProps> = ({ events, clinicalCare }) => (
  <div>
    {
      events?.map((event, index) => (
        <TimelineItem
          key={event?.id}
          event={event}
          last={events.length === index + 1}
          clinicalCare={clinicalCare}
        />
      ))
    }
  </div>
);

export default Timeline;
