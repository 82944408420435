const translations = {
  male: 'Macho',
  female: 'Fêmea',
};

type Gender = 'male' | 'female'

export default function animalGender(gender: Gender): string {
  return translations[gender] || gender;
}
