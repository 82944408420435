/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import currency from '~/lib/masks/currency';
import dateMask from '~/lib/masks/date';

type Item = {
  id: number
  createdAt: string
  title: string
  description: string
  price: number
  quantity: number
}

type ItemsProps = {
  items: Item[]
  price: number
}

const Items = ({ items, price }: ItemsProps) => (
  <div className="py-2">
    <table className="table table-sm text-small">
      <thead>
        <tr>
          <th>Item</th>
          <th style={{ width: 200 }}>Adicionado em</th>
          <th style={{ width: 350 }} className="text-right">Valor</th>
        </tr>
      </thead>
      <tbody>
        {
          items.map((item) => (
            <tr key={item.id}>
              <td>
                <span className="ml-2">
                  <small>
                    {item.quantity}
                    x
                    {' '}
                    {item?.title}
                  </small>
                </span>
                <div>
                  <small>{item?.description}</small>
                </div>
              </td>
              <td><small>{dateMask(item.createdAt)}</small></td>
              <td className="text-right">
                <span className="ml-2"><small>{currency(item.price / 100, 'R$ ')}</small></span>
              </td>
            </tr>
          ))
        }
      </tbody>
      <tfoot>
        <tr>
          <th />
          <th />
          <th className="text-right">
            <small className="text-bold">
              Total:
              {' '}
              {currency(price / 100, 'R$')}
            </small>
          </th>
        </tr>
      </tfoot>
    </table>
  </div>
);

export default Items;
