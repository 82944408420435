import { ApiError } from '~/services/api/interceptors';

function replaceVariables(content: string, variables: any = {}): string {
  return Object.keys(variables)
    .reduce((accumulator, key) => accumulator.replace(
      new RegExp(`%{${key}}`, 'g'),
      variables[key],
    ), content);
}

const recordNotFoundMappings: any = {
  Animal: "Não foi possível encontrar o animal com o RGA '%{uid}'",
  Castration: "Não foi possível encontrar a castração com o código '%{uid}'",
  Batch: "Não foi possível encontrar um lote com o código '%{uid}'",
  AdoptionTerm: 'Não foi possível encontrar Termo de Adoção para o código informado',
  AdoptionFair: "Não foi possível encontrar uma feira para o código informado '%{id}'",
  ClinicalCare: "Não foi possível encontrar atendimento clínico com o código '%{uid}'",
};

export default {
  recordNotFound(error: ApiError) {
    const model: any = error?.additionalProperties?.model;
    const content = recordNotFoundMappings[model];
    return {
      icon: 'fas fa-magnifying-glass fa-3x text-warning',
      message: replaceVariables(content, error?.additionalProperties?.payload),
    };
  },
} as any;
