import React from 'react';
import QRCode from 'react-qr-code';
import { useParams } from 'react-router-dom';
import { LoadManager, PageContainer } from '~/components';
import clinicalCareStatus from '~/constants/enumTranslations/clinicalCareStatus';
import useAxiosRequest from '~/hooks/useAxiosRequest.1';
import usePageTitle from '~/hooks/usePageTitle';
import dateMask from '~/lib/masks/date';
import clinicalCares from '~/services/clinicalCares';
import mask from 'slim-mask-js';
import phoneNumberMask from '~/lib/masks/phoneNumber';
import AnimalShow from './Animal';
import Author from './Author';
import Clinic from './Clinic';
import Records from './Records';
import Prescritions from './Prescritions';
import Items from './Items';
import Events from './Events';
import Comments from './Comments';
import Tutor from './Tutor';
import Provider from './Provider';

const Show: React.FC<{ title: string }> = ({ title }) => {
  usePageTitle({ title });
  const { uid } = useParams();

  const { status, error, data } = useAxiosRequest(clinicalCares.clinicalCareByUid, uid);

  const { animal, ...clinicalCare } = data || {};

  const firstAddress = clinicalCare?.events?.[0]?.address;

  return (
    <PageContainer>
      <LoadManager apiStatus={status} error={error}>
        <div className="py-2">
          <h5 className="h5">Detalhes do atendimento clínico</h5>
          <div className="columns align-center">
            <div className="column col-auto mt-2">
              <QRCode value={window.location.href} size={80} />
            </div>
            <div className="column col-xs-12">
              <div className="columns my-1">
                <div className="column col-xs-12">
                  <small>
                    Código do atendimento:
                    {' '}
                    <b>{mask(clinicalCare?.uid, '####.####.####.####.####')}</b>
                  </small>
                </div>
                <div className="column col-xs-12 col-auto">
                  <small>
                    Status do atendimento:
                    {' '}
                    <b className={`clinical-care-status ${clinicalCare?.status}`}>{clinicalCareStatus(clinicalCare?.status)}</b>
                  </small>
                </div>
              </div>
              <div className="columns my-1">
                <div className="column col-xs-12">
                  <small>
                    Atendimento criado em:
                    {' '}
                    <b>{dateMask(clinicalCare?.createdAt, { hour: '2-digit', minute: '2-digit' })}</b>
                  </small>
                </div>
              </div>
              <div className="columns my-1">
                <div className="column col-xs-12">
                  <small>
                    Número do protocolo:
                    {' '}
                    <b>{clinicalCare?.protocolNumber || '-'}</b>
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="divider" />
        <div className="py-2">
          <h5 className="h5">Informações da pessoa</h5>
          <div className="my-1">
            <span>
              Nome da pessoa:
              {' '}
              <b>{clinicalCare?.extraData?.personName || '-'}</b>
            </span>
          </div>
          <div className="my-1">
            <span>
              Telefone da pessoa:
              {' '}
              <b>{phoneNumberMask(clinicalCare?.extraData?.personPhoneNumber) || '-'}</b>
            </span>
          </div>
        </div>
        {
          Boolean(firstAddress) && (
            <>
              <div className="divider" />
              <div className="py-2">
                <h5 className="h5">O pet se encontra em</h5>
                <div className="my-1">
                  {firstAddress?.formattedAddress}
                </div>
              </div>
            </>
          )
        }
        <div className="divider" />
        <div className="py-2">
          <h5 className="h5">Dados do pet</h5>
          {
            animal ? (
              <div className="columns">
                <AnimalShow animal={animal} />
                <Tutor tutor={clinicalCare?.tutor} />
              </div>
            ) : <p>O pet ainda não foi informado</p>
          }
        </div>
        <div className="divider" />
        <div className="py-2">
          <h5 className="h5">Dados do Fornecedor</h5>
          <Provider provider={clinicalCare?.provider} />
        </div>
        <div className="py-2">
          <h5 className="h5 pagebreak">Observações</h5>
          <div className="pre bg-gray text-small p-2 s-rounded">
            {clinicalCare?.description || '-'}
          </div>
        </div>
        <div className="py-2">
          <h5 className="h5">Envolvidos</h5>
          <div>
            <div className="my-2">
              <Author author={clinicalCare?.author} />
            </div>
            <div className="my-2">
              <Clinic clinic={clinicalCare?.clinic} />
            </div>
          </div>
        </div>
        <div className="divider" />
        <div className="py-2">
          <h5 className="h5">Prontuário</h5>
          {
            clinicalCare?.records?.length ? (
              <Records records={clinicalCare?.records} />
            ) : <p>Nada no prontuário</p>
          }
        </div>
        <div className="divider" />
        <div className="py-2">
          <h5 className="h5">Receitas</h5>
          {
            clinicalCare?.prescritions?.length ? (
              <Prescritions prescritions={clinicalCare?.prescritions} />
            ) : <p>Nenhuma receita</p>
          }
        </div>
        <div className="divider" />
        <div className="py-2">
          <h5 className="h5">Itens utilizados</h5>
          {
            clinicalCare?.items?.length ? (
              <Items items={clinicalCare?.items} price={clinicalCare?.price} />
            ) : <p>Nenhum item utilizado</p>
          }
        </div>
        <div className="py-2">
          <h5 className="h5 pagebreak">Eventos do atendimento</h5>
          {
            clinicalCare?.events?.length ? (
              <Events events={clinicalCare?.events} clinicalCare={clinicalCare} />
            ) : <p>Nenhum evento no atendimento</p>
          }
        </div>
        {
          Boolean(clinicalCare?.comments?.length) && (
            <>
              <div className="divider" />
              <div className="py-2">
                <h5 className="h5">Comentários</h5>
                <Comments comments={clinicalCare?.comments} />
              </div>
            </>
          )
        }
      </LoadManager>
    </PageContainer>
  );
};

export default Show;
