import React from 'react';
import { Card } from '~/components';
import documentNumberMask from '~/lib/masks/documentNumber';
import thumbor from '~/lib/thumbor';
import { Person } from '~/types';

type ClinicProps = {
  clinic: Person
}

const Clinic = ({ clinic }: ClinicProps) => (
  <div key={clinic?.id}>
    <Card.Base className="card custom">
      <Card.Header>
        <div className="align-center">
          <div className="h5">Clínica</div>
        </div>
      </Card.Header>
      <Card.Body>
        <div className="columns align-center col-gapless">
          <div className="column col-auto">
            <figure className="avatar avatar-lg" data-initial={clinic?.fullName?.[0]}>
              {
                Boolean(clinic?.photoURL)
                && (
                  <img
                    src={
                      thumbor(
                        clinic?.photoURL,
                        { width: 64, height: 64 },
                      )
                    }
                    alt="Avatar do pet"
                  />
                )
              }
            </figure>
          </div>
          <div className="column">
            <div className="text-bold px-2">{clinic?.fullName}</div>
            <div>
              <small className="px-2">
                {documentNumberMask(clinic?.documentNumber) || '-'}
              </small>
            </div>
          </div>
        </div>
        <div className="divider" />
        <div className="columns text-small">
          <div className="column">E-mail</div>
          <div className="column col-auto">
            {clinic?.email || '-'}
          </div>
        </div>
        <div className="columns text-small">
          <div className="column">Telefone</div>
          <div className="column col-auto">
            {clinic?.phoneNumber || '-'}
          </div>
        </div>
      </Card.Body>
    </Card.Base>
  </div>
);

export default Clinic;
