import mask from 'slim-mask-js';

function formatCpf(cpf: string): string {
  return mask(cpf, '###.###.###-##');
}

function formatCnpj(cnpj: string): string {
  return mask(cnpj, '##.###.###/####-##');
}

export default function documentNumberMask(str: string): string {
  if (!str) return '';
  const string = str.replace(/D/g, '');
  if (string.length <= 11) return formatCpf(string);

  return formatCnpj(string);
}
