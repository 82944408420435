import React from 'react';
import { Card } from '~/components';
import animalGender from '~/constants/enumTranslations/animalGender';
import thumbor from '~/lib/thumbor';
import { Animal } from '~/types';

type AnimalShowProps = {
  animal: Animal,
}

const AnimalShow: React.FC<AnimalShowProps> = ({ animal }) => (
  <div className="column col-6 col-sm-12">
    <Card.Base className="card custom">
      <Card.Header>
        <div className="align-center">
          <div className="h5">Pet</div>
        </div>
      </Card.Header>
      <Card.Body>
        <div className="columns align-center">
          <div className="column col-auto">
            <figure className="avatar avatar-xl" data-initial={animal?.name?.[0]}>
              {
                Boolean(animal?.photoURL)
                && (
                  <img
                    src={thumbor(animal?.photoURL, { width: 64, height: 64 })}
                    alt="Avatar do pet"
                  />
                )
              }
            </figure>
          </div>
          <div className="column">
            <div className="text-bold">{animal?.name}</div>
            <div className="text-small">
              {animal?.category?.description}
              {' '}
              -
              {' '}
              {animal?.breed?.description}
            </div>
            <div className="text-small">{animalGender(animal?.gender)}</div>
            {animal?.chip ? (
              <div className="text-small">
                Chip:
                {' '}
                {animal?.chip?.uid}
              </div>
            ) : null}
          </div>
        </div>
      </Card.Body>
    </Card.Base>
  </div>
);

export default AnimalShow;
