import {
  Card as Base, Body, Header, Footer,
} from './styles';
import Info from './Info';

export default {
  Base,
  Body,
  Footer,
  Header,
  Info,
};
