import React from 'react';
import { API_STATUS } from '~/hooks/useAxiosRequest';
import { ApiError } from '~/services/api/interceptors';
import mapMessage from './mapMessage';

type LoadManagerProps = {
  apiStatus?: API_STATUS
  children?: React.ReactNode
  error?: ApiError
  style?: React.CSSProperties
}

const LoadManager: React.FC<LoadManagerProps> = ({
  children, apiStatus, error, style,
}) => {
  if (apiStatus === API_STATUS.LOADING) {
    return (
      <div className="empty">
        <div className="empty-icon" style={{ marginBottom: 40 }}>
          <i className="loading loading-lg" />
        </div>
        <p className="empty-title h5">Aguarde...</p>
        <p className="empty-subtitle">Estamos buscando a informação que você solicitou.</p>
      </div>
    );
  }
  if (apiStatus === API_STATUS.ERROR && error) {
    const messageFn = mapMessage[error.code];
    const { message, icon } = messageFn ? messageFn(error) : {
      message: 'Não houve resposta do servidor',
      icon: 'fas fa-heart-crash fa-3x text-warning',
    };

    return (
      <div className="empty">
        <div className="empty-icon">
          <i className={icon} />
        </div>
        <p className="empty-title h5">{error?.message}</p>
        {
          message
            ? <p className="empty-subtitle">{message}</p>
            : null
        }
      </div>
    );
  }

  return <div style={style}>{children}</div>;
};

LoadManager.defaultProps = {
  apiStatus: undefined,
  children: null,
  error: undefined,
  style: {},
};

export default LoadManager;
