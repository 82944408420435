import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { useMemo } from 'react';

export default function useQuery() {
  const location = useLocation();
  const query = useMemo(
    () => qs.parse(location.search.replace('?', '')),
    [location.search],
  );

  return query;
}
