function dateMask(dateString: string, options: Intl.DateTimeFormatOptions = {}): string {
  if (!dateString) return '-';
  try {
    const date = new Date(dateString);
    return date.toLocaleDateString('pt-BR', { ...options });
  } catch (error) {
    return dateString;
  }
}

export default dateMask;
