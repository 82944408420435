import qs from 'qs';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import useQuery from '~/hooks/useQuery';

type PaginatorProps = {
  paginatedList?: { rows: any[]; count: number; page: number; pages: number }
  limitPages?: number
}
function Paginator({ paginatedList, limitPages }: PaginatorProps) {
  const location = useLocation();
  const query = useQuery();

  if (!paginatedList || !limitPages) return null;

  const inFirstPage = paginatedList.page <= 1;
  const inLastPage = paginatedList.page >= paginatedList.pages;

  if (paginatedList.pages <= 1) return null;

  return (
    <ul className="pagination">
      <li className={`page-item ${inFirstPage ? 'disabled' : ''}`}>
        <Link replace to={`${location.pathname}?${qs.stringify({ ...query, page: paginatedList.page - 1 })}`}>
          <i className="fas fa-chevron-left" />
        </Link>
      </li>
      <li className={`page-item ${inFirstPage ? 'active' : ''}`}>
        <Link replace to={`${location.pathname}?${qs.stringify({ ...query, page: 1 })}`}>
          1
        </Link>
      </li>

      {
        paginatedList.page - limitPages > 1
          ? <li className="page-item"><span>...</span></li>
          : null
      }

      {
        Array(paginatedList.pages).fill(null).map((_, index) => {
          const page = index + 1;
          if (page === 1 || page === paginatedList.pages) return null;
          if (page - paginatedList.page > limitPages) return null;
          if (paginatedList.page - page > limitPages) return null;
          return (
            <li key={`paginator-${page}`} className={`page-item ${page === paginatedList.page ? 'active' : ''}`}>
              <Link replace to={`${location.pathname}?${qs.stringify({ ...query, page })}`}>
                {page}
              </Link>
            </li>
          );
        })
      }

      {
        paginatedList.pages - paginatedList.page > limitPages
          ? <li className="page-item"><span>...</span></li>
          : null
      }

      <li className={`page-item ${inLastPage ? 'active' : ''}`}>
        <Link replace to={`${location.pathname}?${qs.stringify({ ...query, page: paginatedList.pages })}`}>
          {paginatedList.pages}
        </Link>
      </li>

      <li className={`page-item ${inLastPage ? 'disabled' : ''}`}>
        <Link replace to={`${location.pathname}?${qs.stringify({ ...query, page: paginatedList.page + 1 })}`}>
          <i className="fas fa-chevron-right" />
        </Link>
      </li>
    </ul>
  );
}

Paginator.defaultProps = {
  paginatedList: null,
  limitPages: 3,
};

export default Paginator;
