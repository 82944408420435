import { MistreatmentResult } from '~/types';

const translations = {
  awaiting: 'Aguardando',
  measuresApplied: 'Medidas legais aplicadas',
  unfounded: 'Não aplicável',
  canceled: 'Cancelado',
};

export default function mistreatmentResult(status: MistreatmentResult): string {
  return translations[status] || status;
}
