import React from 'react';
import {
  FacebookIcon, FacebookShareButton, TelegramIcon, TelegramShareButton,
  WhatsappIcon, WhatsappShareButton, XIcon, TwitterShareButton,
} from 'react-share';
import { toast } from 'react-toastify';
import { CopyButton } from '~/components/Share/styles';

type ShareProps = {
  url: string
  title?: string
  value?: string
}

const Share = ({ url, title, value }: ShareProps) => {
  function copyLink() {
    let text = title ? `${title}\n` : '';
    if (value) text += `${value}\n`;
    text += url;

    navigator.clipboard.writeText(text);
    toast.success('Link copiado com sucesso');
  }

  return (
    <div className="columns">
      <div className="column col-auto">
        <FacebookShareButton className="tooltip tooltip-top" data-tooltip="Compartilhar no Facebook" url={url} title={title} value={value}>
          <FacebookIcon size={22} round />
        </FacebookShareButton>
      </div>
      <div className="column col-auto">
        <WhatsappShareButton className="tooltip tooltip-top" data-tooltip="Compartilhar no WhatsApp" url={url} title={title} value={value}>
          <WhatsappIcon size={22} round />
        </WhatsappShareButton>
      </div>
      <div className="column col-auto">
        <TelegramShareButton className="tooltip tooltip-top" data-tooltip="Compartilhar no Telegram" url={url} title={title} value={value}>
          <TelegramIcon size={22} round />
        </TelegramShareButton>
      </div>
      <div className="column col-auto">
        <TwitterShareButton className="tooltip tooltip-top" data-tooltip="Compartilhar no X (Antigo Twitter)" url={url} title={title} value={value}>
          <XIcon size={22} round />
        </TwitterShareButton>
      </div>
      <div className="column col-auto">
        <CopyButton data-tooltip="Copiar o link" type="button" onClick={() => copyLink()}>
          <i className="fas fa-link" />
        </CopyButton>
      </div>
    </div>
  );
};

Share.defaultProps = {
  title: '',
  value: '',
};

export default Share;
