import styled from 'styled-components';

export const Header = styled.div.attrs({
  className: 'columns align-center py-2',
})`
  border-bottom: 1px solid #eee;
`;

export const Sticky = styled.div.attrs({
  className: 'pt-2 mb-2',
})`
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 9;
`;
