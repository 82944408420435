import styled from 'styled-components';

export const Card = styled.div.attrs<{custom?: Boolean}>({
  className: 'card',
})``;

export const Header = styled.div.attrs({
  className: 'card-header',
})``;

export const Body = styled.div.attrs({
  className: 'card-body',
})``;

export const Footer = styled.div.attrs({
  className: 'card-footer',
})``;
