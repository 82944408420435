import React from 'react';
import mask from 'slim-mask-js';
import animalGender from '~/constants/enumTranslations/animalGender';
import dateMask from '~/lib/masks/date';
import { Animal } from '~/types';

type AnimalShowProps = {
  animal: Animal,
}

const AnimalShow: React.FC<AnimalShowProps> = ({ animal }) => (
  <div>
    <div className="columns">
      <div className="column pb-2">
        <b>Nome do Pet:&nbsp;</b>
        {animal?.name}
      </div>
      <div className="column pb-2">
        <b>Data de Nascimento:&nbsp;</b>
        {dateMask(animal?.bornedAt)}
      </div>
      <div className="column pb-2">
        <b>Gênero:&nbsp;</b>
        {animalGender(animal?.gender)}
      </div>
    </div>
    <div className="columns">
      <div className="column pb-2">
        <b>Espécie:&nbsp;</b>
        {animal?.category?.description}
      </div>
      <div className="column pb-2">
        <b>Raça:&nbsp;</b>
        {animal?.breed?.description}
      </div>
      <div className="column pb-2">
        <b>Cor:&nbsp;</b>
        {animal?.color}
      </div>
    </div>
    <div className="columns">
      <div className="column pb-2">
        <b>Castrado:&nbsp;</b>
        {animal?.isCastrated ? 'Sim' : 'Não'}
      </div>
      <div className="column pb-2">
        <b>RGA:&nbsp;</b>
        {mask(animal?.uid, '####.####.####.####.####')}
      </div>
      <div className="column pb-2">
        <b>Chip:&nbsp;</b>
        {animal?.chip ? animal?.chip?.uid : '-'}
      </div>
    </div>
  </div>
);

export default AnimalShow;
