import { ClinicalCareStatus } from '~/types';

const mapping = {
  screening: {
    title: 'O atendimento está em processo de triagem.\n',
    icon: '',
  },
  forwarded: {
    title: 'Atendimento encaminhado.\n',
    bgIconClass: 'bg-primary',
    icon: 'fas fa-ambulance fa-xs',
  },
  hospitalized: {
    title: 'Pet internado na clínica.\n',
    bgIconClass: 'bg-primary',
    icon: 'fas fa-star-of-life',
  },
  medicalRelease: {
    title: 'O pet recebeu alta.\n',
    bgIconClass: 'bg-success',
    icon: 'fas fa-user-md',
  },
  death: {
    title: 'O pet faleceu.\n',
    bgIconClass: 'bg-dark',
    icon: 'fas fa-cross',
  },
  pullout: {
    title: 'O pet está em processo de retirada.\n',
    bgIconClass: 'bg-primary',
    icon: 'fas fa-undo',
  },
  caught: {
    title: 'O pet foi retirado da clínica.\n',
    bgIconClass: 'bg-success',
    icon: 'fas fa-undo',
  },
  done: {
    title: 'O atendimento foi concluído.\n',
    bgIconClass: 'bg-success',
    icon: 'fas fa-check',
  },
  canceled: {
    title: 'O atendimento foi cancelado.\n',
    bgIconClass: 'bg-error',
    icon: 'fas fa-times',
  },
};

type ReturnType = {title?: string, bgIconClass?: string, icon?: string}
export default function mapEvents(status: ClinicalCareStatus): ReturnType {
  return mapping?.[status] || {};
}
