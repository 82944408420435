import React, { useMemo } from 'react';
import castrationStatus from '~/constants/enumTranslations/castrationStatus';
import dateMask from '~/lib/masks/date';
import thumbor from '~/lib/thumbor';
import { CastrationEvent } from '~/types';
import mapEvents from './mapEvents';

const authorDetailsMap = {
  tutor: {
    icon: 'fas fa-user',
    description: 'Tutor',
  },
  clinic: {
    icon: 'fas fa-house-chimney-medical',
    description: 'Clínica',
  },
  provider: {
    icon: 'fas fa-user-secret',
    description: 'Fornecedor',
  },
};

type TimelineItemProps = {
  event: CastrationEvent
  castration: {tutorId: number, clinicId: number, providerId: number},
  last: boolean
};
const TimelineItem: React.FC<TimelineItemProps> = ({ event, castration, last }) => {
  const mappedEvent = mapEvents(event?.status);
  const { tutorId, clinicId } = castration;

  const authorDetails = useMemo(() => {
    if (event.authorId === tutorId) return authorDetailsMap.tutor;
    if (event.authorId === clinicId) return authorDetailsMap.clinic;

    return authorDetailsMap.provider;
  }, [event, castration]);

  return (
    <div className="inside-avoid">
      <div>
        <span
          className={`timeline-icon tooltip tooltip-right ${mappedEvent?.bgIconClass} ${mappedEvent?.icon ? 'icon-lg' : ''}`}
          data-tooltip={castrationStatus(event.status)}
        />
      </div>
      <div>
        <div className="tile">
          <div className="tile-content">
            <p className="tile-subtitle">
              <span className="text-bold">
                {dateMask(event?.createdAt, { dateStyle: 'full' })}
                &nbsp;
              </span>
              <small className="label label-sm label-rounded">
                <i className="far fa-clock" />
                &nbsp;
                {new Date(event?.createdAt).toLocaleTimeString()}
              </small>
              <small className="label label-sm label-rounded ml-1">
                <i className={authorDetails.icon} />
                &nbsp;
                {authorDetails.description}
              </small>
            </p>
            <p className="tile-title pre">{mappedEvent?.title}</p>
            {
              Boolean(event?.description) && (
                <p className="tile-title">
                  <cite className="pre">{event?.description}</cite>
                </p>
              )
            }
            {
              Boolean(event?.photoURL) && (
                <img
                  src={thumbor(event.photoURL, { width: 230, height: 230 })}
                  className="s-rounded"
                  alt="Imagem do evento"
                />
              )
            }
          </div>
        </div>
      </div>
      {!last && <div className="divider" />}
    </div>
  );
};

export default TimelineItem;
