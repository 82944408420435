import React from 'react';

type PageContainerProps = {
  children?: React.ReactNode
  gridSize?: 'grid-xl' | 'grid-lg' | 'grid-md' | 'grid-sm' | 'grid-xs'
}

const PageContainer: React.FC<PageContainerProps> = (props) => {
  const { children, gridSize } = props;

  return (
    <div className={`container ${gridSize} py-2`}>
      {children}
    </div>
  );
};

PageContainer.defaultProps = { children: null, gridSize: 'grid-lg' };

export default PageContainer;
