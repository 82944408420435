import { useCallback, useEffect, useState } from 'react';

export default ({ title }: { title: string }) => {
  const [previousTitle] = useState(document.title);

  const setTitle = useCallback((newTitle) => {
    document.title = newTitle;
  }, []);

  useEffect(() => {
    if (title) setTitle(title);
  }, [setTitle, title]);

  return { setTitle, previousTitle };
};
