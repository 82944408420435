import { ThumborUrlBuilder } from 'thumbor-url-builder-ts';
import envs from '~/constants/envs';

type Options = {width?: number, height?: number, smart?: boolean};

const thumbor = new ThumborUrlBuilder(
  envs.REACT_THUMBOR_SECURITY_KEY,
  envs.REACT_THUMBOR_BASE_URL,
);

export default (url: string = '', { width, height, smart = true }: Options = {}) => {
  thumbor
    .setImagePath(encodeURIComponent(url))
    .smartCrop(smart);
  if (width && height) {
    thumbor.resize(width, height);
  }
  return thumbor.buildUrl();
};
