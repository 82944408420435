import { CastrationStatus } from '~/types';

const translations = {
  requested: 'Solicitada',
  rejected: 'Rejeitada',
  canceled: 'Cancelada',
  correction: 'Em correção',
  corrected: 'Corrigida pelo tutor',
  approved: 'Aprovada',
  revision: 'Em vistoria',
  attendance: 'Em atendimento',
  awaitingConfirmation: 'Aguardando confirmar',
  completed: 'Concluída',
  scheduled: 'Agendada',
  preApproved: 'Pré aprovada',
  inTreatment: 'Em tratamento',
};

export default function castrationStatus(status: CastrationStatus): string {
  return translations[status] || status;
}
