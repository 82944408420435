import React from 'react';
import QRCode from 'react-qr-code';
import { useParams } from 'react-router-dom';
import mask from 'slim-mask-js';
import { LoadManager, PageContainer } from '~/components';
import mistreatmentResult from '~/constants/enumTranslations/mistreatmentResult';
import mistreatmentStatus from '~/constants/enumTranslations/mistreatmentStatus';
import useAxiosRequest from '~/hooks/useAxiosRequest.1';
import usePageTitle from '~/hooks/usePageTitle';
import dateMask from '~/lib/masks/date';
import thumbor from '~/lib/thumbor';
import mistreatments from '~/services/mistreatments';
import Comments from '~/views/Castration/Show/Comments';

const Show: React.FC<{ title: string }> = ({ title }) => {
  usePageTitle({ title });
  const { uid } = useParams();

  const { status, error, data } = useAxiosRequest(mistreatments.mistreatmentByUid, uid);

  const {
    comments, author, ...mistreatment
  } = data || {};

  return (
    <PageContainer>
      <LoadManager apiStatus={status} error={error}>
        <>
          <div className="py-2">
            <h5 className="h5">Detalhes da denúncia</h5>
            <div className="columns align-center">
              <div className="column col-auto mt-2">
                <QRCode value={window.location.href} size={80} />
              </div>
              <div className="column col-xs-12">
                <div className="columns my-1">
                  <div className="column col-xs-12">
                    <small>
                      Código da denúncia:
                      {' '}
                      <b>{mask(mistreatment?.uid, '####.####.####.####.####')}</b>
                    </small>
                  </div>
                  <div className="column col-xs-12 col-auto">
                    <small>
                      Status da denúncia:
                      {' '}
                      <b className={`mistreatment-status ${mistreatment?.status}`}>
                        {mistreatmentStatus(mistreatment?.status)}
                      </b>
                    </small>
                  </div>
                </div>
                <div className="columns my-1">
                  <div className="column col-xs-12">
                    <small>
                      Data da solicitação:
                      {' '}
                      <b>{dateMask(mistreatment?.createdAt, { hour: '2-digit', minute: '2-digit' })}</b>
                    </small>
                  </div>
                  <div className="column col-xs-12 col-auto">
                    <small>
                      Resultado da denúncia:
                      {' '}
                      <b className={`mistreatment-result ${mistreatment?.result}`}>
                        {mistreatmentResult(mistreatment?.result)}
                      </b>
                    </small>
                  </div>
                </div>
                <div className="columns my-1">
                  <div className="column col-xs-12 col-auto text-ellipsis">
                    <small>
                      Solicitante:
                      {' '}
                      <b>{author?.fullName || 'Denúncia anônima'}</b>
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="divider" />
          <div className="py-2">
            <div>
              <small>Descrição do caso</small>
              <div>{mistreatment.description}</div>
            </div>
          </div>
          {
            Boolean(mistreatment?.images?.length > 0) && (
              <div className="py-2">
                <div>
                  <small>Imagens</small>
                  <div className="columns align-center">
                    {
                      mistreatment.images.map((uri: string) => (
                        <div key={uri} className="column col-auto">
                          <img className="s-rounded" src={thumbor(uri, { width: 120, height: 120 })} alt="Imagem da denúncia" />
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            )
          }
          {
            Boolean(comments?.length) && (
              <>
                <div className="divider" />
                <div className="py-2">
                  <h5 className="h5">Comentários</h5>
                  <Comments comments={comments} />
                </div>
              </>
            )
          }
        </>
      </LoadManager>
    </PageContainer>
  );
};

export default Show;
